// Node Modules
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowMinimize, faXmark } from "@fortawesome/pro-solid-svg-icons";
// Components
import { Modal, Tooltip } from "Components";
import { TechnologySearchWarningModal } from "./Content/TechnologySearchWarningModal/TechnologySearchWarningModal";
// Providers
import { WindowingContext } from "Providers";
// Styles
import styles from "./findestWindow.module.scss";
import { useQuery } from "Hooks";
import { WindowState } from "Classes";

type TFindestWindowProps = {
  openCount: number;
  minimizeCount: number;
  windowId: string;
  children?: ReactNode;
  isHidden: boolean;
  isGraphWindow?: boolean;
};

export const FindestWindow: FC<TFindestWindowProps> = ({
  openCount,
  minimizeCount,
  windowId,
  children,
  isHidden,
  isGraphWindow,
}: TFindestWindowProps) => {
  // Context
  const { idToState, deleteWindow } = useContext(WindowingContext);
  // State
  const [isMinimizedState, setIsMinimizedState] = useState<boolean>(false);
  const [lastMinimizeCount, setLastMinimizeCount] = useState<number>(0);
  const [isMinimizeButtonTooltipOpen, setIsMinimizeButtonTooltipOpen] =
    useState<boolean>(false);
  const [isCloseButtonTooltipOpen, setIsCloseButtonTooltipOpen] =
    useState<boolean>(false);
  const [minimizeButtonReference, setMinimizeButtonReference] =
    useState<HTMLButtonElement | null>(null);
  const [closeButtonReference, setCloseButtonReference] =
    useState<HTMLButtonElement | null>(null);
  const [
    isTechnologySearchWarningModalOpen,
    setIsTechnologySearchWarningModalOpen,
  ] = useState<boolean>(false);

  const { cancelSearchAsync } = useQuery();

  useEffect(() => {
    setIsMinimizedState(false);
  }, [openCount]);

  useEffect(() => {
    if (minimizeCount > lastMinimizeCount) {
      setLastMinimizeCount(minimizeCount);
      setIsMinimizedState(true);
    }
  }, [lastMinimizeCount, minimizeCount]);

  const handleMinimize = () => {
    setIsMinimizedState(true);
  };

  const handleClose = (skipIsTechnologySearchOnResultsCheck = true) => {
    const windowState: WindowState = idToState[windowId];

    if (
      !skipIsTechnologySearchOnResultsCheck &&
      windowState?.queryWindowState?.isTechnologySearchOnResults
    ) {
      setIsTechnologySearchWarningModalOpen(true);
      return;
    }

    if (
      windowState.queryWindowState?.correlationId &&
      windowState.queryWindowState?.type
    ) {
      cancelSearchAsync(
        windowState.queryWindowState.correlationId,
        windowState.queryWindowState.type,
        windowState.queryWindowState.isTechnologySearchOnResults ?? false
      );
    }
    deleteWindow(windowId);
  };

  const showMinimizeButtonTooltip = () => {
    setIsMinimizeButtonTooltipOpen(true);
  };

  const hideMinimizeButtonTooltip = () => {
    setIsMinimizeButtonTooltipOpen(false);
  };

  const showCloseButtonTooltip = () => {
    setIsCloseButtonTooltipOpen(true);
  };

  const hideCloseButtonTooltip = () => {
    setIsCloseButtonTooltipOpen(false);
  };

  return (
    <Modal
      isOpen
      onRequestClose={handleMinimize}
      extraClassNames={{
        container: styles.findestWindowModalContainer,
        overlay: `${styles.findestWindowModalOverlay} ${
          isMinimizedState || isHidden ? styles.hidden : ""
        }`,
      }}
    >
      <div
        className={`${styles.windowActionButtons} ${
          isGraphWindow ? styles.graphWindow : ""
        }`}
      >
        <button
          type="button"
          onMouseEnter={showMinimizeButtonTooltip}
          onMouseLeave={hideMinimizeButtonTooltip}
          ref={setMinimizeButtonReference}
          className={`${styles.windowActionButton} ${styles.minimize}`}
          onClick={handleMinimize}
        >
          <FontAwesomeIcon icon={faWindowMinimize} />
        </button>
        <Tooltip
          referenceEl={minimizeButtonReference}
          isOpen={isMinimizeButtonTooltipOpen}
          tooltipText="Minimize window"
        />
        <button
          type="button"
          onMouseEnter={showCloseButtonTooltip}
          onMouseLeave={hideCloseButtonTooltip}
          ref={setCloseButtonReference}
          className={`${styles.windowActionButton} ${styles.close}`}
          onClick={() => {
            handleClose(false);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <Tooltip
          referenceEl={closeButtonReference}
          isOpen={isCloseButtonTooltipOpen}
          tooltipText="Close window"
        />
      </div>
      <div className={styles.modalContent}>{children}</div>
      <TechnologySearchWarningModal
        isOpen={isTechnologySearchWarningModalOpen}
        setIsOpen={setIsTechnologySearchWarningModalOpen}
        closeWindow={handleClose}
        minimizeWindow={handleMinimize}
      />
    </Modal>
  );
};
