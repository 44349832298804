// React
import { useContext, useEffect } from "react";
// Providers
import { PubSubContext } from "Providers";
// Enums
import { PubSubEventTypeEnum } from "Enums";
import { IQueryDTO } from "Interfaces";

export const useQueryCreatedListener = (
  onQueryCreated: (query: IQueryDTO) => void
) => {
  // Contexts
  const { pubSubHandler } = useContext(PubSubContext);

  useEffect(() => {
    pubSubHandler.subscribeToEvent(
      PubSubEventTypeEnum.QueryCreated,
      onQueryCreated
    );

    return () => {
      pubSubHandler.unsubscribeFromEvent(
        PubSubEventTypeEnum.QueryCreated,
        onQueryCreated
      );
    };
  }, [onQueryCreated, pubSubHandler]);
};
