import { FC } from "react";
import { IconDefinition, faArrowRightLong } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./createQueryWizard.module.scss";

type TWizardOptionProps = {
  icon: IconDefinition;
  title: string;
  onClick?: () => void;
}

export const WizardOption: FC<TWizardOptionProps> = ({ icon, title, onClick }: TWizardOptionProps) => {
  return (
    <button type="button" className={styles.option} onClick={onClick}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {title}
      <div className={styles.arrowIconContainer}>
        <FontAwesomeIcon icon={faArrowRightLong} />
      </div>
    </button>
  );
};
