import { FC, LegacyRef } from "react";
import { IconDefinition as regularIconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition as solidIconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import { LoadingStatusIndicator } from "Components";
// Enums
import { LoadingStatusEnum } from "Enums";
// Types
import { TButtonType } from "Types";
// Styles
import styles from "./findestButton.module.scss";

type TFindestButtonProps = {
  id?: string;
  title?: string;
  type?: "button" | "submit" | "reset";
  extraClassName?: string;
  isColorless?: boolean;
  isSmallSize?: boolean;
  leftIconName?: regularIconDefinition | solidIconDefinition;
  rightIconName?: regularIconDefinition | solidIconDefinition;
  onClick?: () => void;
  isDisabled?: boolean;
  buttonType?: TButtonType;
  styleProps?: ("noWrap" | "textTransformNone" | "noPadding" | "fullWidth" | "justifyCenter")[];
  children?: React.ReactNode;
  titleAttribute?: string;
  ref?: LegacyRef<HTMLButtonElement> | undefined;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
  isLoading?: boolean;
};

export const FindestButton: FC<TFindestButtonProps> = ({
  id,
  title,
  type,
  extraClassName,
  isColorless,
  isSmallSize,
  leftIconName,
  rightIconName,
  onClick,
  isDisabled,
  buttonType,
  children,
  titleAttribute,
  styleProps,
  ref,
  onMouseOver,
  onMouseOut,
  isLoading
}: TFindestButtonProps) => {
  const getClassName = (): string => {
    const classNameList = [styles.findestButton];
    if (extraClassName) classNameList.push(extraClassName);
    if (isColorless) classNameList.push(styles.colorless);
    if (isSmallSize) classNameList.push(styles.small);
    if (isDisabled) classNameList.push(styles.disabled);
    if (isLoading) classNameList.push(styles.isLoading);
    if (buttonType) classNameList.push(styles[buttonType]);
    if (!title && !children) classNameList.push(styles.iconOnly);

    if (leftIconName) classNameList.push(styles.hasLeftIcon);
    if (rightIconName) classNameList.push(styles.hasRightIcon);

    if (styleProps) {
      styleProps.forEach((prop) => {
        classNameList.push(styles[prop]);
      });
    }

    return classNameList.join(" ");
  };

  return (
    <button
      disabled={isDisabled}
      // eslint-disable-next-line react/button-has-type
      type={type ?? "button"}
      id={id}
      onClick={onClick}
      className={getClassName()}
      title={titleAttribute ? titleAttribute : ""}
      ref={ref}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {leftIconName && (
        <FontAwesomeIcon icon={leftIconName} className={styles.leftIcon} />
      )}
      {isLoading && <LoadingStatusIndicator status={LoadingStatusEnum.Loading} size={16} />}
      {title}
      {children}
      {rightIconName && (
        <FontAwesomeIcon icon={rightIconName} className={styles.rightIcon} />
      )}
    </button>
  );
};
