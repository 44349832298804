import * as signalR from "@microsoft/signalr";
import { AxiosHelperSingleton } from "Helpers";

export class WebsocketController {
  private connection?: signalR.HubConnection;

  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}`;

  constructor() {
    this.initializeVisibilityChangeHandler();
  }

  private initializeVisibilityChangeHandler() {
    document.addEventListener("visibilitychange", () =>
      this.handleVisibilityChange()
    );
    this.handleVisibilityChange();
  }

  private async handleVisibilityChange() {
    if (document.visibilityState === "visible") {
      await this.startIfNeeded();
    }
  }

  public buildSignalRConnection() {
    // if connection is already set, do not build a new one
    if (this.connection) {
      // stop execution, return
      return;
    }

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${this._resourcePath}totalhub`, {
        accessTokenFactory: () =>
          `${AxiosHelperSingleton.getCommonHeader("Authorization")}`.split(
            " "
          )[1],
      })
      .withAutomaticReconnect()
      .build();
  }

  public async startIfNeeded() {
    // safety-checks
    if (!this.connection) {
      // stop execution, return
      return;
    }

    while (this.connection.state === signalR.HubConnectionState.Connecting) {
      await new Promise((r) => setTimeout(r, 50));
    }

    if (this.connection.state !== signalR.HubConnectionState.Connected) {
      await this.connection.start();
    }
  }

  public async addHandler(
    methodName: string,
    handler: (...args: any[]) => void
  ) {
    // safety-checks
    if (!this.connection) {
      // stop execution, return
      return;
    }

    await this.startIfNeeded();
    this.connection.on(methodName, handler);
  }

  public removeHandler(methodName: string, handler: (...args: any[]) => void) {
    // safety-checks
    if (!this.connection) {
      // stop execution, return
      return;
    }

    this.connection.off(methodName, handler);
  }

  public async invokeFunction(methodName: string, ...args: any[]) {
    // safety-checks
    if (!this.connection) {
      // stop execution, return
      return;
    }

    await this.startIfNeeded();
    await this.connection.invoke(methodName, ...args);
  }
}

export const WebsocketControllerSingleton = new WebsocketController();
