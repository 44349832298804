import { Dispatch, FC, SetStateAction } from "react";
import { FindestButton, Modal } from "Components";
import styles from "./technologySearchWarningModal.module.scss";
import TopbarOptionsImage from "Assets/Images/TopbarOptions.png";

interface ITechnologySearchWarningModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  closeWindow: () => void;
  minimizeWindow: () => void;
}

export const TechnologySearchWarningModal: FC<ITechnologySearchWarningModalProps> =
  ({
    isOpen,
    setIsOpen,
    closeWindow,
    minimizeWindow,
  }: ITechnologySearchWarningModalProps) => {
    const onCloseClick = () => {
      setIsOpen(false);
      closeWindow();
    };

    const onMinizeClick = () => {
      setIsOpen(false);
      minimizeWindow();
    };

    const onStayClick = () => {
      setIsOpen(false);
    };

    return (
      <Modal
        header="Are you sure you want to close?"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        extraClassNames={{ container: styles.technologySearchWarningModal }}
      >
        <p className={styles.paragraph}>
          When you close the query modal, <span className={styles.underlinedText}>the results won&apos;t be saved</span>. You
          have to create a technology overview again next time you want to go
          through the results, which takes up to 5 minutes.
        </p>
        <p className={styles.paragraph}>
          Alternatively, click on the <span className={styles.minimizeIcon}>—</span> icon next to the cross to minimize the
          modal. It will perserve the results of your query while getting out of
          your way.
        </p>
        <img loading="lazy" className={styles.topbarOptionsImage} src={TopbarOptionsImage} alt="Topbar options" />
        <p className={styles.paragraph}>
          When minimized, it will be accessible throughout the platform in the
          top left on every screen.
        </p>
        <div className={styles.footer}>
          <FindestButton
            title="Yes, close the query"
            type="button"
            buttonType="danger"
            onClick={onCloseClick}
          />
          <FindestButton
            title="No, minimize instead"
            type="button"
            buttonType="secondary"
            onClick={onMinizeClick}
          />
          <FindestButton
            title="No, I want to stay"
            type="button"
            buttonType="secondary"
            onClick={onStayClick}
          />
        </div>
      </Modal>
    );
  };
