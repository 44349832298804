import { IQueryWindowState } from "Interfaces";

export class WindowState {
  private readonly _windowId: string;
  public isMinimized = false;
  public openCount = 0;
  public minimizeCount = 0;
  public openOrder = 0;
  public queryWindowState?: IQueryWindowState;

  constructor(windowId?: string) {
    if (windowId) {
      this._windowId = windowId;
    } else {
      this._windowId = crypto.randomUUID();
    }
  }

  public get windowId(): string {
    return this._windowId;
  }
}
