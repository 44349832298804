export enum PubSubEventTypeEnum {
    RemoveLinkFrom = "RemoveLinkFrom",
    RemoveLink = "RemoveLink",
    AddLinkTo = "AddLinkTo",
    AddLink = "AddLink",
    ObjectLinked = "ObjectLinked",
    NameChange = "NameChange",
    TypeChange = "TypeChange",
    ObjectDeleted = "ObjectDeleted",
    QueryCreated = "QueryCreated",
}