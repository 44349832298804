import { useCallback } from "react";

export const useHighlighting = () => {
  const highlightText = useCallback(
    (text: string, toHighlight: string[]): string => {
      if (!text || !toHighlight || toHighlight.length === 0) {
        return text;
      }

      const positions: number[][] = [];
      const lowerText = text.toLowerCase();

      // collect all start and end positions for each target string
      toHighlight.forEach((originalStr) => {
        const lowerStr = originalStr.toLowerCase();
        let startIndex = 0;
        while ((startIndex = lowerText.indexOf(lowerStr, startIndex)) !== -1) {
          positions.push([startIndex, startIndex + lowerStr.length]);
          startIndex++;
        }
      });

      // sort by start index first, then by end index
      positions.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

      // merge overlapping and adjacent intervals
      const mergedPositions: number[][] = [];
      positions.forEach(([start, end]) => {
        if (mergedPositions.length === 0) {
          mergedPositions.push([start, end]);
        } else {
          const last = mergedPositions[mergedPositions.length - 1];
          if (start <= last[1]) {
            // extend the interval
            last[1] = Math.max(last[1], end);
          } else {
            mergedPositions.push([start, end]);
          }
        }
      });

      // insert <span> tags into the original text
      let offset = 0;
      mergedPositions.forEach(([start, end]) => {
        text = `${text.slice(
          0,
          start + offset
        )}<span class="noun-highlight">${text.slice(
          start + offset,
          end + offset
        )}</span>${text.slice(end + offset)}`;
        offset += '<span class="noun-highlight"></span>'.length;
      });

      return text;
    },
    []
  );

  return {
    highlightText,
  };
};
