import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  partition as d3Partition,
  arc as d3Arc,
  hierarchy as d3Hierarchy,
  HierarchyRectangularNode
} from "d3";
import styles from "./sunburstChart.module.scss";
import { TSunburstChart } from "Types";

type TSunburstChartProps = {
  data: TSunburstChart;
  selectedDataItem?: string;
  changeSelectedDataItem?: (selectedDataItem: string, depth: number) => void;
  extraClassNames?: { container?: string };
}
export const SunburstChart: FC<TSunburstChartProps> = ({
  data, selectedDataItem, changeSelectedDataItem, extraClassNames
}: TSunburstChartProps) => {
  const SIZE = 975;
  const RADIUS = SIZE / 2;

  const svgRef = useRef<SVGSVGElement>(null);

  const [selected, setSelected] = useState<string | undefined>(selectedDataItem ?? undefined);
  const [tooltip, setTooltip] = useState<{ visible: boolean; x: number; y: number; text: string }>({
    visible: false,
    x: 0,
    y: 0,
    text: "",
  });

  useEffect(() => {
    setSelected(selectedDataItem);
  }, [selectedDataItem]);

  const partition = (currentData: TSunburstChart) =>
    d3Partition<TSunburstChart>()
      .size([Math.PI, RADIUS])(
        d3Hierarchy(currentData).count().sort((a, b) => b.height - a.height)
      );

  const arc = d3Arc<HierarchyRectangularNode<TSunburstChart>>()
    .startAngle((d) => d.x0 - Math.PI / 2)
    .endAngle((d) => d.x1 - Math.PI / 2)
    .padAngle((d) => Math.min((d.x1 - d.x0) / 2, 0.005))
    .innerRadius((d) => {
      // Increase the inner radius for the first layer
      if (d.depth === 1) {
        return d.y0 + RADIUS / 4;
      }

      if (d.depth === 2 && d.parent) {
        // Start the second layer where the first one ends
        return (d.parent.y0 + RADIUS / 4) + ((d.parent.y1 - d.parent.y0));
      }

      return d.y0;
    })
    .outerRadius((d) => {
      if (d.depth === 1) {
        // Increase the outer radius to accommodate the increased inner radius
        return d.y1 + RADIUS / 4 - 4;
      }

      if (d.depth === 2) {
        return RADIUS;
      }

      return d.y1;
    });

  const root = partition(data);

  const colors = useMemo(() => {
    const colorArray = ["#4D0037", "#750055", "#A30076", "#BC4398", "#CC70B1", "#E8BFDC", "#F8ECF5"];
    const extendedColors = [...colorArray, ...colorArray.slice().reverse()]; // Normal and then reversed
    
    const hierarchyData = d3Hierarchy(data);
    let lastColor = ""; // Track the last color used to avoid duplicates

    const baseColors = hierarchyData.children?.reduce((acc, node, index) => {
      // Find the next color that is not the same as the last used color
      let color = "";
      for (let i = 0; i < extendedColors.length; i++) {
        color = extendedColors[(index + i) % extendedColors.length]; // Cycle through extendedColors
        if (color !== lastColor) {
          lastColor = color; // Update the last color
          break; // Exit loop when a valid color is found
        }
      }
      acc[node.data.name] = color;
      return acc;
    }, {} as Record<string, string>);
    return baseColors;
  }, [data]);

  const changeSelectedItem = (currData: HierarchyRectangularNode<TSunburstChart>) => {
    if (currData.data.selectable) {
      setSelected(currData.data.id);
    }
    changeSelectedDataItem?.(currData.data.id, currData.depth);
  };

  const getItemColor = (d: HierarchyRectangularNode<TSunburstChart>) => {
    if (!colors) return "#F2F4F8";

    const { id } = d.data;
    const parentId = d.parent?.data.id;
  
    if (selected) {
      if (id === selected || parentId === selected) {
        return colors[id] || (parentId && colors[parentId]);
      }
      return "#F2F4F8";
    }
  
    return colors[id] || (parentId && colors[parentId]);
  };

  const showTooltip = (event: React.MouseEvent<SVGPathElement>, text: string) => {
    const svgElement = svgRef.current;
    if (svgElement) {
      const rect = svgElement.getBoundingClientRect();
      setTooltip({
        visible: true,
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
        text,
      });
    }
  };

  const hideTooltip = () => {
    setTooltip({ ...tooltip, visible: false });
  };

  return (
    <div className={`${extraClassNames?.container ?? ""} ${styles.sunburstChartContainer}`}>
      <svg
        className={styles.sunburstChart}
        width={SIZE}
        height={SIZE / 2}
        viewBox={`-${SIZE / 2} -${SIZE / 2} ${SIZE} ${SIZE / 2}`}
        ref={svgRef}
      >
        <g fillOpacity={1}>
          {root
            .descendants()
            .filter((d) => d.depth)
            .map((d, i) => (
              <path
                key={`${d.data.name}-${i}`}
                fill={getItemColor(d)}
                d={arc(d) ?? undefined}
                onClick={() => changeSelectedItem(d)}
                onMouseMove={(event) => showTooltip(event, d.data.name)}
                onMouseLeave={hideTooltip}
                data-name={d.data.name}
              />
            ))}
        </g>
      </svg>
      {tooltip.visible && (
        <div
          style={{
            position: "absolute",
            minWidth: "180px",
            left: tooltip.x,
            top: tooltip.y,
            backgroundColor: "white",
            borderRadius: "4px",
            fontSize: "14px",
            boxShadow: "2px 2px 6px 0px rgba(0,0,0,0.3)",
            padding: "8px",
            pointerEvents: "none",
            transform: "translate(-50%, -100%)",
          }}
        >
          {tooltip.text}
        </div>
      )}
    </div>
  );
};