// node_modules
import { FC } from "react";
// Styles
import styles from "./createNewModal.module.scss";
import { IconDefinition as regularIconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { IconDefinition as solidIconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type TCreateNewModalCardProps = {
  title: string;
  description: string;
  iconName: regularIconDefinition | solidIconDefinition;
  children?: React.ReactNode;
  extraClassName?: string;
  isWider?: boolean;
};

export const CreateNewModalCard: FC<TCreateNewModalCardProps> = ({
  title,
  description,
  iconName,
  children,
  extraClassName,
  isWider
}: TCreateNewModalCardProps) => {
  // Render
  return (
    <div className={styles.createNewCard}>
      <div
        className={[
          styles.cardHeader,
          extraClassName ? extraClassName : null,
        ].join(" ")}
      >
        <div className={styles.cardTitle}>
          <FontAwesomeIcon icon={iconName} />
          <h2>{title}</h2>
        </div>
        <p className={styles.cardDescription}>{description}</p>
      </div>
      <div className={`${styles.cardContent} ${isWider ? styles.isWider : ""}`}>{children}</div>
    </div>
  );
};
