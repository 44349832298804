import { faUpRightAndDownLeftFromCenter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  BarElement,
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { FC, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { TPublicationsPerYearDTO } from "Types";
import { DateHelperSingleton } from "Helpers";
import styles from "./publicationsPerYear.module.scss";
import { PublicationsPerYearModal } from "./PublicationsPerYearModal/PublicationsPerYearModal";

// Register ChartJS elements
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

type TPublicationsPerYearProps = {
  publicationsPerYear?: TPublicationsPerYearDTO;
  startDate?: string;
  endDate?: string;
};

export const PublicationsPerYear: FC<TPublicationsPerYearProps> = ({
  publicationsPerYear,
  startDate,
  endDate,
}: TPublicationsPerYearProps) => {
  const [isPublicationsPerYearModalOpen, setIsPublicationsPerYearModalOpen] =
    useState(false);

  const onEnlargeIconClick = () => {
    setIsPublicationsPerYearModalOpen(true);
  };

  const barChartData = useMemo(():
    | ChartData<"bar", number[], string>
    | undefined => {
    // if publicationsPerYear is null, undefined or empty
    // or publicationsPerYear.publicationsPerYear is null, undefined or empty
    if (!publicationsPerYear?.publicationsPerYear) {
      // stop execution, return undefined
      return undefined;
    }

    // init labels and data array
    const labels: string[] = [];
    const data: number[] = [];

    // parse start date to date object
    const parsedStartDate: Date | undefined =
      startDate && DateHelperSingleton.isValidDate(startDate)
        ? new Date(startDate)
        : undefined;
    // parse end date to date object
    const parsedEndDate: Date | undefined =
      endDate && DateHelperSingleton.isValidDate(endDate)
        ? new Date(endDate)
        : undefined;

    // go through each publications per year
    for (const year in publicationsPerYear.publicationsPerYear) {
      // parse year to integer
      const parsedYear = parseInt(year, 10);

      // if year is not a number
      // or year is less than 2000 (exclusive)
      // or start date is set and year is less than start date year (exclusive)
      // or end date is set and year is greater than end date year (exclusive)
      // or year is greater than current year (exclusive)
      // or publications per year is less than 0 (exclusive)
      if (
        isNaN(parsedYear) ||
        parsedYear < 2000 ||
        (parsedStartDate && parsedYear < parsedStartDate.getFullYear()) ||
        (parsedEndDate && parsedYear > parsedEndDate.getFullYear()) ||
        parsedYear > new Date().getFullYear() ||
        publicationsPerYear.publicationsPerYear[year] < 0
      ) {
        // stop execution, continue
        continue;
      }

      // add year to labels
      labels.push(year);
      // add related publications to data
      data.push(publicationsPerYear.publicationsPerYear[year]);
    }

    // safety-checks
    if (
      !labels ||
      !data ||
      labels.length !== data.length ||
      labels.length === 0 ||
      data.length === 0
    ) {
      // stop execution, return undefined
      return undefined;
    }

    // return bar chart data
    return {
      labels,
      datasets: [
        {
          // $study-count-color
          backgroundColor: "#5856D6",
          data,
        },
      ],
    };
  }, [publicationsPerYear, startDate, endDate]);

  // bar chart options
  const barChartOptions: ChartOptions<"bar"> = {
    elements: {
      bar: {
        // $findest-gray
        hoverBackgroundColor: "#CCCCCC",
      },
    },
    plugins: {
      tooltip: {
        displayColors: false,
        callbacks: {
          label: (context: TooltipItem<"bar">) => {
            return `${context.raw} publications`;
          },
        },
      },
    },
  };

  return barChartData ? (
    <>
      <div className={styles.publicationsPerYearContainer}>
        <div className={styles.publicationsPerYearHeader}>
          <h3 className={styles.publicationsPerYearTitle}>
            Publications per year
          </h3>
          <FontAwesomeIcon
            className={styles.enlargePublicationsPerYearIcon}
            icon={faUpRightAndDownLeftFromCenter}
            onClick={onEnlargeIconClick}
          />
        </div>
        <Bar
          options={barChartOptions}
          data={barChartData}
          className={styles.publicationsPerYearContent}
        />
      </div>
      <PublicationsPerYearModal
        isOpen={isPublicationsPerYearModalOpen}
        setIsOpen={setIsPublicationsPerYearModalOpen}
        barChartData={barChartData}
        barChartOptions={barChartOptions}
      />
    </>
  ) : null;
};
