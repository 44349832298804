import { FC } from "react";
import {
  IDocumentExtractedMetadata,
  IDocumentSearchResult,
  IQueryDTO,
} from "Interfaces";
import { ExtractedTechnology } from "../ExtractedTechnology/ExtractedTechnology";
import styles from "./extractedMetadata.module.scss";

interface IExtractedMetadataProps {
  query: IQueryDTO;
  document?: IDocumentSearchResult;
  extractedMetadata: IDocumentExtractedMetadata;
  updateDocument?: (document: IDocumentSearchResult) => void;
}

export const ExtractedMetadata: FC<IExtractedMetadataProps> = ({
  query,
  document,
  extractedMetadata,
  updateDocument,
}) => {
  return (
    <div className={styles.extractedMetadata}>
      <ExtractedTechnology
        extraClassNames={{ title: styles.extractedTechnologyTitle, chip: styles.chip }}
        query={query}
        document={document}
        extractedTechnology={extractedMetadata.technology}
        updateDocument={updateDocument}
      />
      {extractedMetadata.justification && (
        <div className={styles.justification}>
          <span>{extractedMetadata.justification}</span>
        </div>
      )}
    </div>
  );
};
