import { FC, useEffect, useMemo, useState } from "react";
import {
  Dropdown,
  FindestButton,
  FindestTextBox,
  Modal,
  UserIcon,
} from "Components";
import { QueryConstants } from "Constants";
import { SearchQueryTypeEnum } from "Enums";
import { LogHelperSingleton } from "Helpers";
import styles from "./nameYourQueryModal.module.scss";

type TNameYourQueryModalProps = {
  onCreateQueryClickAsync: (
    currentIsCreateQueryButtonDisabled: boolean,
    currentQueryName: string,
    searchQueryType: SearchQueryTypeEnum
  ) => void;
  isOpen: boolean;
  closeModal: () => void;
  selectedQueryType: SearchQueryTypeEnum;
  userEmail: string;
};

export const NameYourQueryModal: FC<TNameYourQueryModalProps> = ({
  isOpen,
  closeModal,
  onCreateQueryClickAsync,
  selectedQueryType,
  userEmail,
}: TNameYourQueryModalProps) => {
  const [currentSelectedQueryType, setCurrentSelectedQueryType] =
    useState<SearchQueryTypeEnum>(selectedQueryType);

  useEffect(() => {
    setCurrentSelectedQueryType(selectedQueryType);
  }, [selectedQueryType]);

  useEffect(() => {
    LogHelperSingleton.log("StartCreatingQuery");
  }, []);

  const dropdownOptions = [
    {
      options: [
        {
          title: QueryConstants.SEARCH_FOR_TECHNOLOGIES,
          value: SearchQueryTypeEnum.UniverseTechnologies,
        },
        {
          title: QueryConstants.SEARCH_FOR_SCIENTIFIC_PAPERS,
          value: SearchQueryTypeEnum.UniverseScienceArticles,
        },
        {
          title: QueryConstants.SEARCH_FOR_PATENTS,
          value: SearchQueryTypeEnum.UniversePatents,
        },
      ],
    },
  ];

  const [queryName, setQueryName] = useState<string>("");

  const isCreateQueryButtonDisabled = useMemo(() => {
    return !queryName;
  }, [queryName]);

  const onQueryNameModalClose = () => {
    closeModal();
  };

  const onCreateQueryClick = () => {
    onCreateQueryClickAsync(
      isCreateQueryButtonDisabled,
      queryName,
      currentSelectedQueryType
    );
  };

  const onSearchQueryTypeChange = (option: {
    value: SearchQueryTypeEnum;
    title: string;
  }) => {
    setCurrentSelectedQueryType(option.value);
  };

  const selectedOption = currentSelectedQueryType
    ? dropdownOptions[0].options.find(
        (option) => option.value === currentSelectedQueryType
      )
    : undefined;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onQueryNameModalClose}
      extraClassNames={{ container: styles.createQueryWizardModal, closeButton: `${styles.closeButton} ${styles.close}` }}
    >
      <div className={styles.modalContent}>
        <UserIcon
          email={userEmail}
          showUserIconTooltip
          extraClassName={styles.userIcon}
          size="large"
        />
        <div className={styles.modalBodyContent}>
          <Dropdown
            className={styles.queryTypeDropdownContainer}
            classNameSelect={styles.queryTypeDropdown}
            selectedOption={selectedOption}
            options={dropdownOptions}
            handleOptionSelect={onSearchQueryTypeChange}
          />
          <h3 className={styles.header}>Name your query</h3>
          <p>
            As a first step, give your query a recognizable name so you&apos;ll
            be able to easily find it in the list. When iterating and creating
            variations of the same query specify this in the name.
          </p>
          <FindestTextBox
            extraClassName={styles.queryNameInput}
            placeholder="Query name"
            onChange={(text: string) => {
              setQueryName(text);
            }}
          />
          <div className={styles.footer}>
            <FindestButton
              title="Create query"
              isDisabled={isCreateQueryButtonDisabled}
              onClick={onCreateQueryClick}
            />
            <FindestButton
              title="Cancel"
              buttonType="secondary"
              onClick={onQueryNameModalClose}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
