import axios from "axios";
import { SavedDocumentTypeEnum } from "Enums";
import { AxiosHelperSingleton, LogHelperSingleton } from "Helpers";
// Interfaces
import { IDocumentSearchResult, ISavedDocumentDTO } from "Interfaces";
// Types
import { TDocumentDTO } from "Types";

export class DocumentController {
  private _resourcePath = `${AxiosHelperSingleton.getServerBaseURL()}api/document`;

  public async updateDocumentTitle(
    documentId: string,
    title: string
  ): Promise<boolean> {
    try {
      const formData = new FormData();
      formData.append("title", title);

      const response = await axios.post(
        `${this._resourcePath}/${documentId}/customTitle`,
        formData
      );

      return response?.data;
    } catch {
      return false;
    }
  }

  public async validateAddDocumentAsync(
    query: string
  ): Promise<TDocumentDTO | undefined> {
    try {
      const response = await axios.get<TDocumentDTO>(
        `${this._resourcePath}/addDocumentValidation`,
        {
          params: {
            query: query,
          },
        }
      );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async createAsync(
    document: TDocumentDTO,
    logProperties?: { ActionOrigin?: string; QueryGuid?: string }
  ): Promise<ISavedDocumentDTO | undefined> {
    try {
      const response = await axios.post<ISavedDocumentDTO>(
        `${this._resourcePath}`,
        document
      );

      if (response?.data) {
        if (logProperties) {
          LogHelperSingleton.logWithProperties("SaveDocument", {
            DocumentId: document.id,
            ...logProperties,
          });
        }
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async createWithoutWebAsync(
    documentId: string | number,
    documentType: SavedDocumentTypeEnum,
    logProperties?: { ActionOrigin?: string; QueryGuid?: string }
  ): Promise<ISavedDocumentDTO | undefined> {
    return this.createAsync(
      { id: documentId, type: documentType, webpageUrl: "", webpageTitle: "" },
      logProperties
    );
  }

  public async getRelatedScienceArticles(
    documentId: string
  ): Promise<IDocumentSearchResult[] | undefined> {
    try {
      const response = await axios.get(
        `${this._resourcePath}/sciencearticle/${documentId}/relatedsciencearticles`
      );

      if (response?.data) {
        return response.data;
      } else {
        return undefined;
      }
    } catch {
      return undefined;
    }
  }

  public async getDocumentSearchResultByIds(
    ids: string[]
  ): Promise<IDocumentSearchResult[]> {
    try {
      const response = await axios.get<IDocumentSearchResult[]>(
        `${this._resourcePath}/searchresult`,
        {
          params: {
            ids,
          },
        }
      );

      if (response?.data) {
        return response.data;
      } else {
        return [];
      }
    } catch {
      return [];
    }
  }
}

export const DocumentControllerSingleton = new DocumentController();
