// node_modules
import { Dispatch, FC, SetStateAction, useState, useEffect } from "react";
import {
  faListDropdown,
  faEllipsisStroke,
} from "@fortawesome/pro-solid-svg-icons";
// Components
import { QueryFilters, ToggleButton } from "Components";
import { ActionObjects } from "./ActionObjects/ActionObjects";
import { EnvironmentVariables } from "./EnvironmentVariables/EnvironmentVariables";
import { SpecificationUnits } from "./SpecificationUnits/SpecificationUnits";
import { SearchQueryType } from "Components/Queries/SearchQueryType/SearchQueryType";
// Styles
import styles from "./queryFields.module.scss";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Enums
import { SearchQueryTypeEnum, QueryPrioritySelectionStyleEnum } from "Enums";
// Constants
import { FeatureToggleConstants } from "Constants";
// Helpers
import { QueryPrioritySelectionStyleHelperSingleton } from "Helpers";

type TQueryFieldsProps = {
  query: IQueryDTO;
  setQuery: Dispatch<SetStateAction<IQueryDTO | undefined>>;
  searchQueryType: SearchQueryTypeEnum;
  onSearchQueryTypeUpdate: (newSearchQueryType: SearchQueryTypeEnum) => void;
  isGroupedSearch: boolean;
  setIsGroupedSearch: Dispatch<SetStateAction<boolean>>;
};

export const QueryFields: FC<TQueryFieldsProps> = ({
  query,
  setQuery,
  searchQueryType,
  onSearchQueryTypeUpdate,
  isGroupedSearch,
  setIsGroupedSearch,
}) => {
  // State
  const [isSearchTermPriorityDropdown, setIsSearchTermPriorityDropdown] =
    useState<boolean>(true);

  // UseEffect
  useEffect(() => {
    const getSelectionStyle =
      QueryPrioritySelectionStyleHelperSingleton.getActivatedSelectionStyle();
    if (getSelectionStyle === "PriorityDropdown") {
      setIsSearchTermPriorityDropdown(true);
    } else {
      setIsSearchTermPriorityDropdown(false);
    }
  }, []);

  const onClickToggleButton = (isLeftToggleActive: boolean) => {
    setIsSearchTermPriorityDropdown(isLeftToggleActive);

    // Save search term priority selection style to local storage
    if (isLeftToggleActive) {
      QueryPrioritySelectionStyleHelperSingleton.saveActivatedSelectionStyle(
        QueryPrioritySelectionStyleEnum.PriorityDropdown
      );
    } else {
      QueryPrioritySelectionStyleHelperSingleton.saveActivatedSelectionStyle(
        QueryPrioritySelectionStyleEnum.PriorityButtons
      );
    }
  };

  return (
    <div className={styles.queryFieldsContainer}>
      <div className={styles.querySearchTermsContainer}>
        <div className={styles.querySearchTermsTitleContainer}>
          <h2>Search terms</h2>
          <ToggleButton
            toggleIconLeft={faListDropdown}
            toggleTitleAttributeLeft="Searchterm priority dropdown"
            toggleIconRight={faEllipsisStroke}
            toggleTitleAttributeRight="Searchterm priority buttons"
            leftToggleActive={isSearchTermPriorityDropdown}
            onClickToggleButton={onClickToggleButton}
            extraClassName={styles.searchPriorityStyleSelection}
          />
        </div>

        <div className={styles.querySearchTerms}>
          <div className={styles.actionObjectAndSpecsContainer}>
            <ActionObjects
              query={query}
              setQuery={setQuery}
              isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
            />
          </div>
          <div className={styles.keywordsContainer}>
            <EnvironmentVariables
              query={query}
              setQuery={setQuery}
              isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
            />
          </div>
        </div>
      </div>
      <div className={styles.queryFiltersContainer}>
        <h2>Filters</h2>
        <div className={styles.querySearchFilters}>
          <SearchQueryType
            searchQueryType={searchQueryType}
            onSearchQueryTypeUpdate={onSearchQueryTypeUpdate}
            isGroupedSearch={isGroupedSearch}
            setIsGroupedSearch={setIsGroupedSearch}
          />
          {FeatureToggleConstants.UnitSearching && (
            <div>
              <SpecificationUnits
                query={query}
                setQuery={setQuery}
                isSearchTermPriorityDropdown={isSearchTermPriorityDropdown}
              />
            </div>
          )}
          <div>
            <QueryFilters
              query={query}
              setQuery={setQuery}
              searchQueryType={searchQueryType}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
