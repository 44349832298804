import { FC } from "react";
import { FindestButton, HorizontalLoader } from "Components";
import { QueryResultViewTypeEnum } from "Enums";
import styles from "./searchProgressIndicator.module.scss";

interface ISearchProgressIndicatorProps {
  queryResultView: QueryResultViewTypeEnum;
  cancelSearch: () => void;
}

export const SearchProgressIndicator: FC<ISearchProgressIndicatorProps> = ({
  queryResultView,
  cancelSearch,
}) => {
  return (
    <div className={styles.loadingIndicatorContainer}>
      <h3>Searching</h3>
      <p className={styles.description}>
        {queryResultView ===  QueryResultViewTypeEnum.TechnologyResult
          ? "This will take a while, on average 5 minutes."
          : "This might take up to 2 minutes, depending on the complexity of your query."}
      </p>
      <p className={styles.description}>
        It will happen in the background so you can minimize this screen and be
        notified the moment the results are ready.
      </p>
      <HorizontalLoader extraClassName={styles.horizontalLoader} speed={queryResultView ===  QueryResultViewTypeEnum.TechnologyResult ? "medium" : "fast"} />
      <p className={styles.smallDescription}>
        {queryResultView ===  QueryResultViewTypeEnum.TechnologyResult
          ? "Generating keywords, looking for papers and extracting technologies..."
          : "Matching keywords and looking for papers..."}
      </p>
      <FindestButton
        onClick={cancelSearch}
        buttonType="secondary"
        title="Cancel search"
        extraClassName={styles.cancelSearchButton}
      />
    </div>
  );
};
