import { useContext } from "react";
import { WebsocketContext } from "Providers";
import { LogFeatureNameEnum, SearchQueryTypeEnum } from "Enums";
import { IExecuteQueryParams } from "Interfaces";
import { LogHelperSingleton } from "Helpers";
import { WebsocketFunctionNames } from "Constants";

export const useQuery = () => {
  const { webSocketController } = useContext(WebsocketContext);

  const startDocumentSearchAsync = async ({
    query,
    searchQueryType,
    doGroupDocumentSearchResults,
    searchAmount,
    filteredResultNames,
    groupName,
    sortQuery,
    page: currentPage,
    isPaginate,
  }: Partial<IExecuteQueryParams>) => {
    if (searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Science${
          isPaginate ? "-Paginate" : ""
        }`,
        { QueryGuid: query?.guid }
      );
    } else if (searchQueryType === SearchQueryTypeEnum.UniversePatents) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Patent${
          isPaginate ? "-Paginate" : ""
        }`,
        { QueryGuid: query?.guid }
      );
    }

    await webSocketController.invokeFunction(
      WebsocketFunctionNames.StartSearchQueryById,
      query?.guid,
      searchQueryType,
      currentPage,
      doGroupDocumentSearchResults,
      searchAmount,
      filteredResultNames ?? [],
      groupName ?? null,
      sortQuery
    );
  };

  const startTechnologySearchAsync = async ({
    query,
    isTechnologySearchOnResults,
    documents,
  }: Partial<IExecuteQueryParams>) => {
    if (isTechnologySearchOnResults) {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-TechnologyOnResults`,
        { QueryGuid: query?.guid }
      );
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StartTechnologySearchOnResults,
        query?.id,
        query?.filters?.technologyFilters?.naturalLanguageQuery,
        query?.filters?.technologyFilters?.doGroupResults,
        documents
      );
    } else {
      LogHelperSingleton.logWithProperties(
        `${LogFeatureNameEnum.AdvancedSearch}-ExecuteQuery-Technology`,
        { QueryGuid: query?.guid }
      );
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StartTechnologySearch,
        query?.id,
        query?.filters?.technologyFilters?.naturalLanguageQuery,
        query?.filters?.technologyFilters?.igorHits
      );
    }
  };

  const cancelSearchAsync = async (
    correlationId: string,
    searchQueryType: SearchQueryTypeEnum,
    isTechnologySearchOnResults: boolean
  ) => {
    if (
      searchQueryType === SearchQueryTypeEnum.UniverseTechnologies ||
      isTechnologySearchOnResults
    ) {
      await webSocketController.invokeFunction(
        WebsocketFunctionNames.StopTechnologySearch,
        correlationId
      );
      return;
    }
    await webSocketController.invokeFunction(
      WebsocketFunctionNames.CancelSearch,
      correlationId
    );
  };

  return {
    startDocumentSearchAsync,
    startTechnologySearchAsync,
    cancelSearchAsync,
  };
};
