// node_modules
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
// Types
import {
  TAuth,
  TJsonWebTokenDTO,
  TLoginResponseDTO,
  TValidatedSharedToDTO,
} from "Types";
// Controllers
import {
  AuthControllerSingleton,
  SharedToControllerSingleton,
} from "Controllers";
// Enums
import { SharedToSettingsEnum } from "Enums";
// Helpers
import {
  AuthenticationHelperSingleton,
  AxiosHelperSingleton,
  ExtensionCommunicationHelperSingleton,
  UserHelperSingleton,
} from "Helpers";

const defaultAuth: TAuth = {
  userId: "",
  userEmail: "",
  isTwoFactorRequired: false,
  isSharedToCodePageShown: false,
  sharedToSettings: undefined,
  roles: [],
  tenantName: "",
  tenantId: "",
  isRequestingAuthInformation: true,
  isRequestingSharedToSettings: true,
  hasPassword: false,
  permissions: [],
  isRememberMeEnabled: true,
  accessToken: "",
  refreshToken: "",
};

export type TAuthContext = {
  auth: TAuth;
  isUserExternal: boolean;
  isUserViewer: boolean;
  isUserConnected: boolean;
  isUserAtLeastViewer: boolean;
  isUserAtLeastContributor: boolean;
  setAuth: Dispatch<SetStateAction<TAuth>>;
  setDefaultAuth: () => void;
  checkIsObjectSharedToUser: (email: string, objectId: string) => Promise<void>;
  checkIsObjectSharedToCurrentUser: (objectId: string) => Promise<void>;
  verifyAuth: (
    isSharedToCodePageShown: boolean,
    sharedToSettings?: SharedToSettingsEnum
  ) => void;
  getNewSharingCode: () => Promise<void>;
  hasAdvanced: boolean;
};

export const defaultAuthContext: TAuthContext = {
  auth: defaultAuth,
  isUserExternal: false,
  isUserViewer: false,
  isUserConnected: false,
  isUserAtLeastViewer: false,
  isUserAtLeastContributor: false,
  setAuth: () => {
    return;
  },
  setDefaultAuth: () => {
    return;
  },
  checkIsObjectSharedToUser: async () => {
    return undefined;
  },
  verifyAuth: async () => {
    return;
  },
  getNewSharingCode: async () => {
    return;
  },
  checkIsObjectSharedToCurrentUser: async () => {
    return;
  },
  hasAdvanced: false,
};

type TAuthProviderProps = {
  children?: ReactNode;
};

export const AuthContext = createContext<TAuthContext>(defaultAuthContext);

export const AuthProvider = ({ children }: TAuthProviderProps) => {
  // State
  const [auth, setAuth] = useState<TAuth>(defaultAuth);
  const [lastSharedToId, setLastSharedToId] = useState<string>("");

  // Memo
  const isUserExternal = useMemo((): boolean => {
    return UserHelperSingleton.isUserExternalByAuth(auth);
  }, [auth]);
  const isUserViewer = useMemo((): boolean => {
    return UserHelperSingleton.isUserViewer(auth);
  }, [auth]);
  const isUserAtLeastViewer = useMemo((): boolean => {
    return UserHelperSingleton.isUserAtLeastViewer(auth);
  }, [auth]);
  const isUserAtLeastContributor = useMemo((): boolean => {
    return UserHelperSingleton.isUserAtLeastContributor(auth);
  }, [auth]);
  const isUserConnected = useMemo((): boolean => {
    // if auth is not set or userEmail or tenantName are not set
    if (
      !auth ||
      !auth.userEmail ||
      !auth.userId ||
      !auth.tenantName ||
      !auth.tenantId
    ) {
      // return false
      return false;
    } else {
      // otherwise return true
      return true;
    }
  }, [auth]);

  // use to set default auth state in context on login page, two factor page and during logout
  const setDefaultAuth = () => {
    // set default auth (default values and isRequestingAuthInformation, isRequestingSharedToSettings to false)
    setAuth({
      ...defaultAuth,
      isRequestingAuthInformation: false,
      isRequestingSharedToSettings: false,
    });
  };

  // use on the share code page to get a new sharing code (send by email)
  const getNewSharingCode = useCallback(async (): Promise<void> => {
    // send request to back-end to get a new sharing code (send by email)
    await SharedToControllerSingleton.validateShare(
      auth.userEmail,
      lastSharedToId
    );
  }, [auth.userEmail, lastSharedToId]);

  // use in editor references provider to check if the object is shared to the current user
  const checkIsObjectSharedToCurrentUser = useCallback(
    async (objectId: string): Promise<void> => {
      // if current user is not read-only
      if (!UserHelperSingleton.isUserExternalByRoles(auth.roles)) {
        // then do not check if the object is shared to the current user
        return;
      }

      // if the object is already checked (objectId equals to lastSharedToId state value)
      if (objectId === lastSharedToId) {
        // then do not check if the object is shared to the current user
        return;
      }

      // send request to back-end to get if the object is shared to the current user
      const isObjectSharedToCurrentUser: TValidatedSharedToDTO =
        await SharedToControllerSingleton.getSharedWithMeAsync(objectId);

      // if the object is not shared to the current user or the sharedToSettings value is not set
      if (!isObjectSharedToCurrentUser.isShared) {
        // sign/log out the current user
        await AuthControllerSingleton.logoutAsync();
        // reset the authentication with the plugin
        await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();
        // reload the page
        location.reload();
        // stop execution
        return;
      }

      // update context with the new sharedToSettings value (undefined if not shared, otherwise the sharedToSettings value)
      setAuth({
        ...auth,
        sharedToSettings: isObjectSharedToCurrentUser.settings,
      });

      // update lastSharedToId state value
      setLastSharedToId(objectId);
    },
    [auth, lastSharedToId]
  );

  // use in App component to check if the object is shared to the user with the email address from the shared to url
  const checkIsObjectSharedToUser = useCallback(
    async (userEmailAddress: string, objectId: string): Promise<void> => {
      // set lastSharedToId state value to objectId
      setLastSharedToId(objectId);

      // send request to back-end to get if the object is shared to the user with the email address from the shared to url
      const isObjectSharedToUser: TValidatedSharedToDTO =
        await SharedToControllerSingleton.validateShare(
          userEmailAddress,
          objectId
        );

      // update auth state context values
      // set userEmail to userEmailAddress
      // set isRequestingSharedToSettings to false (shared to settings retrieving done)
      // set isRequestingAuthInformation to false (auth data retrieving done)
      // set isSharedToCodePageShown to true if the object is shared to the user (if shared to then back-end sent a 6 digit code to the user email address)
      // set sharedToSettings to the sharedToSettings value (undefined if not shared, otherwise the sharedToSettings value)
      setAuth({
        ...auth,
        userEmail: userEmailAddress,
        isRequestingSharedToSettings: false,
        isRequestingAuthInformation: false,
        isSharedToCodePageShown: isObjectSharedToUser.isShared,
        sharedToSettings: isObjectSharedToUser.settings,
      });
    },
    [auth]
  );

  const verifyAuth = useCallback(
    async (
      isSharedToCodePageShown: boolean,
      sharedToSettings?: SharedToSettingsEnum
    ): Promise<void> => {
      // send verify request
      const loginResponse: TLoginResponseDTO | undefined =
        await AuthControllerSingleton.verifyAsync();

      // decode access token
      const jwt: TJsonWebTokenDTO | undefined =
        AuthenticationHelperSingleton.decodeAccessToken(
          loginResponse ? loginResponse.accessToken : ""
        );

      // init isSuccess to true
      let isSuccess = true;

      // safety-checks
      if (!jwt || !AuthenticationHelperSingleton.isJWTValid(jwt)) {
        // set isSuccess to false
        isSuccess = false;
      }

      // if isSuccess is true and jwt is set
      if (isSuccess && jwt) {
        // set Authorization header
        AxiosHelperSingleton.setCommonHeaderConfigDefaults(
          "Authorization",
          `Bearer ${loginResponse?.accessToken}`
        );

        // reset the authentication with the plugin
        await ExtensionCommunicationHelperSingleton.resetAuthenticationAsync();

        // update auth state context values
        setAuth((prevAuth) => ({
          ...prevAuth,
          userEmail:
            jwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
          roles:
            jwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
          tenantId: jwt.tenantId,
          userId:
            jwt[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
            ],
          permissions: jwt.permissions,
          tenantName: jwt.tenantName,
          isRequestingAuthInformation: false,
          isRequestingSharedToSettings: false,
          isSharedToCodePageShown,
          sharedToSettings,
        }));
      } else {
        // update auth state context values
        setAuth((prevAuth) => ({
          ...prevAuth,
          isRequestingAuthInformation: false,
          isRequestingSharedToSettings: false,
        }));
      }
    },
    []
  );

  useEffect(() => {
    // check auth
    verifyAuth(false);
  }, [verifyAuth]);

  // memo to check if the user has advanced access
  const hasAdvanced = useMemo(() => {
    // check if the user has access to advanced (based on user permissions)
    return UserHelperSingleton.hasAdvanced(auth.permissions);
  }, [auth]);

  const authContextProps = useMemo(() => {
    return {
      auth,
      isUserConnected,
      isUserViewer,
      isUserExternal,
      isUserAtLeastViewer,
      isUserAtLeastContributor,
      setAuth,
      setDefaultAuth,
      checkIsObjectSharedToUser,
      verifyAuth,
      getNewSharingCode,
      hasAdvanced,
      checkIsObjectSharedToCurrentUser,
    };
  }, [
    auth,
    verifyAuth,
    checkIsObjectSharedToCurrentUser,
    checkIsObjectSharedToUser,
    getNewSharingCode,
    hasAdvanced,
    isUserAtLeastContributor,
    isUserAtLeastViewer,
    isUserConnected,
    isUserExternal,
    isUserViewer,
  ]);

  // Render
  return (
    <AuthContext.Provider value={authContextProps}>
      {children}
    </AuthContext.Provider>
  );
};
