// React
import { FC, useCallback, useEffect, useState } from "react";
// Interfaces
import { IConnectedObjectsProps } from "Interfaces";
// Components
import { ConnectedObjects } from "Components";
// Types
import { TIdNameTypeObjectType } from "Types";
// Contexts
import { ConnectedObjectsHelperSingleton } from "Helpers";
// Websocket listener hooks
import { useObjectNameChangeListener, useSpecificLinkAddedListener, useSpecificLinkRemovedListener } from "Hooks";

export const PubSubConnectedObjects: FC<IConnectedObjectsProps> = ({ connectedObjects, mainObjectId, 
        mainObjectType, onConnectToObjectClick, disableConnectToNewObjectButton,
        extraClassName, setContainerElementReference, doHideTitleOnEmptyOrUnsetConnectedObjects, onUpdateConnectedObjectsCounts }) => {

    // State
    const [currentlyConnectedObjects, setCurrentlyConnectedObjects] = useState<TIdNameTypeObjectType[]>(connectedObjects);

    // Handle the removal of a linked connected to this object
    const handleRemoveLinkFrom = useCallback((objectId: string) => {
        setCurrentlyConnectedObjects((oldConnectedObjects: TIdNameTypeObjectType[]) => {
            return oldConnectedObjects.filter((connectedObject: TIdNameTypeObjectType) => {
                return connectedObject.id !== objectId;
            });
        });
    }, []);

    // Handle the addition of a linked connected to this object
    const handleAddLinkTo = useCallback((linkedObject: TIdNameTypeObjectType) => {
        setCurrentlyConnectedObjects((oldConnectedObjects: TIdNameTypeObjectType[]) => {
            ConnectedObjectsHelperSingleton.addNewConnectedObjectToObject(linkedObject, oldConnectedObjects);
            return [...oldConnectedObjects];
        });
    }, []);

    useEffect(() => {
        onUpdateConnectedObjectsCounts?.(currentlyConnectedObjects);
    }, [currentlyConnectedObjects]);

    // Pub sub hooks
    useObjectNameChangeListener(undefined, setCurrentlyConnectedObjects);
    useSpecificLinkRemovedListener(mainObjectId, handleRemoveLinkFrom);
    useSpecificLinkAddedListener(mainObjectId, handleAddLinkTo);

    return (
        <ConnectedObjects mainObjectId={mainObjectId} connectedObjects={currentlyConnectedObjects} mainObjectType={mainObjectType}
            onConnectToObjectClick={onConnectToObjectClick}
            disableConnectToNewObjectButton={disableConnectToNewObjectButton} extraClassName={extraClassName}
            setContainerElementReference={setContainerElementReference} doHideTitleOnEmptyOrUnsetConnectedObjects={doHideTitleOnEmptyOrUnsetConnectedObjects} />
    );
};