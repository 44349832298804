// node_modules
import { Dispatch, FC, SetStateAction } from "react";
// Enums
import { SearchQueryTypeEnum } from "Enums";
// Components
import { Checkbox, RadioButton } from "Components";
// Styles
import styles from "./searchQueryType.module.scss";
import { FeatureToggleConstants } from "Constants";

type TSearchQueryTypeProps = {
  searchQueryType: SearchQueryTypeEnum;
  onSearchQueryTypeUpdate: (newSearchQueryType: SearchQueryTypeEnum) => void;
  isGroupedSearch: boolean;
  setIsGroupedSearch: Dispatch<SetStateAction<boolean>>;
};

export const SearchQueryType: FC<TSearchQueryTypeProps> = ({
  searchQueryType,
  onSearchQueryTypeUpdate,
  isGroupedSearch,
  setIsGroupedSearch,
}: TSearchQueryTypeProps) => {
  // Render
  return (
    <div className={styles.userFlowDataList}>
      <h6 className={`${styles.dataListTitle} ${styles.sourceType}`}>
        Source type
      </h6>
      <div className={styles.dataListItem}>
        <RadioButton
          checked={
            searchQueryType === SearchQueryTypeEnum.UniverseScienceArticles
          }
          onChange={() => {
            onSearchQueryTypeUpdate(
              SearchQueryTypeEnum.UniverseScienceArticles
            );
          }}
          text="Science"
          value={SearchQueryTypeEnum.UniverseScienceArticles}
        />
      </div>
      <div className={styles.dataListItem}>
        <RadioButton
          checked={searchQueryType === SearchQueryTypeEnum.UniversePatents}
          onChange={() => {
            onSearchQueryTypeUpdate(SearchQueryTypeEnum.UniversePatents);
          }}
          text="Patent"
          value={SearchQueryTypeEnum.UniversePatents}
        />
      </div>

      {FeatureToggleConstants.GroupedSearch && (
        <div className={styles.dataListItem}>
          <Checkbox
            isChecked={isGroupedSearch}
            onCheckboxChange={setIsGroupedSearch}
            text="Group search results"
          />
        </div>
      )}
    </div>
  );
};
