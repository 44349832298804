// node_modules
import { useMemo, useState } from "react";

export const usePagination = (countInterval: number, totalCount: number, onPaginateAsync: (isPaginateNext: boolean) => Promise<void>): {
    paginationIndicator: string,
    paginateToNextPageAsync: () => Promise<void>,
    paginateToPreviousPageAsync: () => Promise<void>,
    currentPageNumber: number,
    isLastPage: boolean,
    isLoading: boolean,
    resetPagination: () => void,
    currentTotalCount: number,
    setTotalCount: (newTotalCount: number) => void,
    setCurrentPageNumber: (pageNumber: number) => void,
}=> {
    // State
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [currentTotalCount, setCurrentTotalCount] = useState<number>(totalCount);
    const [isLoading, setIsLoading] = useState(false);

    // Const
    const currentStartNumber = useMemo(() => currentPageNumber === 1 ? 1 : ((currentPageNumber - 1) * countInterval) + 1,
        [countInterval, currentPageNumber]);
    const estimatedEndNumber = useMemo(() => currentStartNumber + countInterval - 1,
        [countInterval, currentStartNumber]);
    const isLastPage = useMemo(() => estimatedEndNumber >= currentTotalCount, 
        [currentTotalCount, estimatedEndNumber]);
    const currentEndNumber = useMemo(() => isLastPage ? currentTotalCount : estimatedEndNumber,
        [currentTotalCount, estimatedEndNumber, isLastPage]);
    const paginationIndicator = useMemo(() => `${currentEndNumber > 0 ? currentStartNumber : 0} - ${currentEndNumber} of ${currentTotalCount}`, 
        [currentEndNumber, currentStartNumber, currentTotalCount]);

    // Logic
    const resetPagination = (): void => {
        // reset current page number
        // then every const that depends on current page number will be updated
        setCurrentPageNumber(1);
    };

    const paginateToPreviousPageAsync = async (): Promise<void> => {
        if ((currentPageNumber > 1) && !isLoading) {
            setIsLoading(true);
            await onPaginateAsync(false);
            setCurrentPageNumber(currentPageNumber - 1);
            setIsLoading(false);      
        }
    };

    const paginateToNextPageAsync = async (): Promise<void> => {
        if ((currentPageNumber * countInterval < currentTotalCount) && !isLoading) {
            setIsLoading(true);
            await onPaginateAsync(true);
            setCurrentPageNumber(currentPageNumber + 1);
            setIsLoading(false);
        }
    };

    // Return
    return { paginationIndicator, paginateToNextPageAsync, paginateToPreviousPageAsync, currentPageNumber,
        isLastPage, resetPagination, currentTotalCount, setTotalCount: setCurrentTotalCount, setCurrentPageNumber, isLoading };
};