import { useCallback, useMemo, useState } from "react";
import debounce from "lodash.debounce";
import { IQueryDTO } from "Interfaces";
import { TQueryFiltersDTO } from "Types";

export const useNaturalLanguageQuery = (query: IQueryDTO, updateQueryFilters: (queryFilters: TQueryFiltersDTO) => void) => {
  const [naturalLanguageQuery, setNaturalLanguageQuery] = useState<string>(query?.filters?.technologyFilters?.naturalLanguageQuery ?? "");

  const onTextAreaChange = useCallback((newNaturalLanguageQuery: string) => {
    updateQueryFilters({
      ...query.filters,
      technologyFilters: {
        ...query.filters.technologyFilters,
        naturalLanguageQuery: newNaturalLanguageQuery,
      }
    });
  }, [query, updateQueryFilters]);

  const debouncedOnTextAreaChange = useMemo(
    () => debounce(onTextAreaChange, 1000),
    [onTextAreaChange]
  );

  const onNaturalLanguageQueryUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newNaturalLanguageQuery = e.target.value;
    setNaturalLanguageQuery(newNaturalLanguageQuery);
    debouncedOnTextAreaChange(newNaturalLanguageQuery);
  };

  return {
    naturalLanguageQuery,
    onNaturalLanguageQueryUpdate,
  };
};
