import { FC, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-solid-svg-icons";
import styles from "./warning.module.scss";

interface IWarningProps {
  children?: ReactNode;
  extraClassNames?: { container?: string };
}

export const Warning: FC<IWarningProps> = ({
  children, extraClassNames
}: IWarningProps) => {
  return (
    <div className={`${styles.warningContainer} ${extraClassNames?.container ?? ""}`}>
      <FontAwesomeIcon icon={faTriangleExclamation} />
      {children}
    </div>
  );
};

