import { DocumentControllerSingleton } from "Controllers";
import { ToastTypeEnum, LogFeatureNameEnum } from "Enums";
import {
  ToastHelperSingleton,
  ObjectTypeHelperSingleton,
  ConnectedObjectsHelperSingleton,
} from "Helpers";
import { IDocumentSearchResult, ISavedDocumentDTO } from "Interfaces";
import { PubSubContext } from "Providers";
import { useCallback, useContext } from "react";
import { TIdNameTypeObjectType, TReadDocumentDTO } from "Types";

export const useDocument = () => {
  const { pubSubHandler } = useContext(PubSubContext);

  const loadDocumentSearchResultMetadataAsync = useCallback(
    async (
      documents: IDocumentSearchResult[]
    ): Promise<IDocumentSearchResult[]> => {
      const newDocuments: IDocumentSearchResult[] = [];

      const documentIds: string[] = documents.map(
        (document) => document.documentId
      );

      const documentsWithMetadata: IDocumentSearchResult[] =
        await DocumentControllerSingleton.getDocumentSearchResultByIds(
          documentIds
        );

      for (let document of documents) {
        const documentWithMetadata: IDocumentSearchResult | undefined =
          documentsWithMetadata.find(
            (currDocument) => currDocument.documentId === document.documentId
          );

        if (documentWithMetadata) {
          document = {
            ...documentWithMetadata,
          };
        }

        newDocuments.push(document);
      }

      return newDocuments;
    },
    []
  );

  const loadIsAlreadyReadOnDocumentResultsAsync = useCallback(
    async (
      documents: IDocumentSearchResult[],
      readDocuments: TReadDocumentDTO[]
    ): Promise<IDocumentSearchResult[]> => {
      const newDocuments: IDocumentSearchResult[] = documents.map(
        (onDocument: IDocumentSearchResult) => {
          const isAlreadyRead: boolean = readDocuments.some((readDocument) => {
            return readDocument.documentId === onDocument.documentId;
          });

          return {
            ...onDocument,
            isAlreadyRead,
          };
        }
      );

      return newDocuments;
    },
    []
  );

  const sortDocumentByPublicationDate = useCallback(
    (a: Partial<IDocumentSearchResult>, b: Partial<IDocumentSearchResult>) => {
      const dateA = a.searchInformation?.publicationDate
        ? new Date(a.searchInformation.publicationDate).getTime()
        : 0;
      const dateB = b.searchInformation?.publicationDate
        ? new Date(b.searchInformation.publicationDate).getTime()
        : 0;

      return dateB - dateA;
    },
    []
  );

  const onSaveElementClickAsync = useCallback(
    async (
      element: TIdNameTypeObjectType,
      currentDocument: IDocumentSearchResult,
      currentUpdateDocument?: (document: IDocumentSearchResult) => void,
      queryGuid?: string
    ): Promise<void> => {
      // if currentUpdateDocument is not set
      if (!currentUpdateDocument) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
            element.objectType
          ).toLowerCase()} to document.`
        );
        return;
      }

      /**
       * We don’t have any way to tell if the document is saved before from the search results.
       * If we add that information, it might make the search slower.
       * On the list page, check connectedObjects of the document, if there is a connected object, don’t log "SaveDocument".
       * It can still happen that we send too many save document logs,
       * because the document can be saved from plugin or create new modal. This is an edge case.
       */
      let saveDocumentLogProperties = undefined;
      let isDocumentAlreadySaved = false;
      // Check createdByUsername and dateAdded just in case we have these properties set
      if (currentDocument.createdByUsername && currentDocument.dateAdded) {
        isDocumentAlreadySaved = true;
      } else if (currentDocument.connectedObjects) {
        isDocumentAlreadySaved = currentDocument.connectedObjects.length > 0;
      }
      if (!isDocumentAlreadySaved) {
        if (queryGuid) {
          saveDocumentLogProperties = {
            ActionOrigin: LogFeatureNameEnum.AdvancedSearch,
            QueryGuid: queryGuid,
          };
        }
      }

      // save document
      const savedDocument: ISavedDocumentDTO | undefined =
        await DocumentControllerSingleton.createWithoutWebAsync(
          currentDocument.documentId,
          currentDocument.documentType,
          saveDocumentLogProperties
        );

      // if savedDocument is not set
      if (!savedDocument) {
        // show error message
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
            element.objectType
          ).toLowerCase()} to document.`
        );
        // stop execution, return
        return;
      }

      // add object to current document
      await ConnectedObjectsHelperSingleton.addObjectToObjectAsync(
        element,
        pubSubHandler,
        savedDocument.id,
        ObjectTypeHelperSingleton.documentTypeToObjectType(
          currentDocument.documentType
        ),
        queryGuid ? LogFeatureNameEnum.AdvancedSearch : undefined
      );

      // update document
      currentUpdateDocument(currentDocument);
    },
    [pubSubHandler]
  );

  return {
    loadDocumentSearchResultMetadataAsync,
    loadIsAlreadyReadOnDocumentResultsAsync,
    sortDocumentByPublicationDate,
    onSaveElementClickAsync,
  };
};
