import { FC } from "react";
import { QuerySortOptionsEnum } from "Enums";
import { QuerySortOptionsHelperSingleton } from "Helpers";
import { FindestButton } from "Components";

export type TSortButtonProps = {
    sortOption: QuerySortOptionsEnum,
    currentSort: string,
    onSortBy: (sortOption: QuerySortOptionsEnum) => void
};

export const SortButton: FC<TSortButtonProps> = ({ sortOption, currentSort, onSortBy }: TSortButtonProps) => {
    const sortSearchValue = QuerySortOptionsHelperSingleton.getQuerySortOptionSearchValue(sortOption);
    const sortDisplayValue = QuerySortOptionsHelperSingleton.getQuerySortOptionDisplayValue(sortOption);

    // do not render if the current sort is the same as the sort option
    if (currentSort === sortSearchValue) return null;

    // Render
    return (
        <FindestButton 
            styleProps={["fullWidth"]} 
            title={sortDisplayValue} 
            buttonType="tertiary"
            onClick={() => onSortBy(sortOption)} />
    );
};