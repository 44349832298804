// React
import { FC } from "react";
// Node Modules
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
// Styles
import styles from "./loadingStatusIndicator.module.scss";
// Enums
import { LoadingStatusEnum } from "Enums";

type TLoadingStatusIndicatorProps = {
  status: LoadingStatusEnum;
  size?: number;
  shouldCenter?: boolean;
  extraClassNames?: { container?: string; };
};

export const LoadingStatusIndicator: FC<TLoadingStatusIndicatorProps> = ({
  status,
  size = 24,
  shouldCenter,
  extraClassNames = {}
}: TLoadingStatusIndicatorProps) => {
  if (status === LoadingStatusEnum.NotStarted) return null;

  const sizeString = `${size}px`;

  return (
    <div className={`${shouldCenter ? styles.shouldCenter : ""} ${extraClassNames?.container ?? ""}`}>
      {status === LoadingStatusEnum.Loading && (
        <div
          style={{
            width: sizeString,
            height: sizeString,
          }}
          className={styles.spinner}
        ></div>
      )}
      {status === LoadingStatusEnum.Done && (
        <FontAwesomeIcon
          className={styles.done}
          style={{ width: sizeString, height: sizeString }}
          icon={faCheckCircle}
        />
      )}
      {status === LoadingStatusEnum.Failed && (
        <FontAwesomeIcon
          className={styles.failed}
          style={{ width: sizeString, height: sizeString }}
          icon={faCircleXmark}
        />
      )}
    </div>
  );
};
