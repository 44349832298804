// node_modules
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
// Classes
import { WindowState } from "Classes";
// Components
import { FindestWindow, LinksWindow } from "Components";
// Constants
import { WindowingConstants } from "Constants";
// Enums
import { LinksWindowTabsEnum, ObjectTypeEnum } from "Enums";
// Helpers
import { LogHelperSingleton } from "Helpers";
// Interfaces
import { IQueryDTO, IQueryWindowState } from "Interfaces";
// Types
import { TIdNameTypeObjectType } from "Types";
import { LinkGraphContext } from "./LinkGraphContext";

type TWindowingContext = {
  idToState: {
    [key: string]: WindowState;
  };
  searchWindowPreviews: TIdNameTypeObjectType[];
  addWindow(node: ReactNode): string;
  deleteWindow(windowId: string): void;
  reopenWindow(windowId: string): void;
  addSearchWindow: (
    query: IQueryDTO,
    node: ReactNode,
    actionOrigin: string
  ) => void;
  openGraph(defaultSelectedTab?: LinksWindowTabsEnum): void;
  minimizeAllWindows: (exceptId?: string) => void;
  graphViewSelectedTab: LinksWindowTabsEnum | undefined;
  setGraphViewSelectedTab: (tab: LinksWindowTabsEnum | undefined) => void;
  updateQueryWindowState: (
    windowId: string,
    queryWindowState?: IQueryWindowState
  ) => void;
};

export const defaultWindowingContext: TWindowingContext = {
  idToState: {},
  searchWindowPreviews: [],
  addWindow: () => {
    return "";
  },
  deleteWindow: () => {
    return;
  },
  reopenWindow: () => {
    return;
  },
  addSearchWindow: () => {
    return;
  },
  openGraph: () => {
    return;
  },
  minimizeAllWindows: () => {
    return;
  },
  graphViewSelectedTab: undefined,
  setGraphViewSelectedTab: () => {
    return;
  },
  updateQueryWindowState: () => {
    return;
  },
};

export const WindowingContext = createContext<TWindowingContext>(
  defaultWindowingContext
);

type TWindowingProviderProps = {
  children?: ReactNode;
};

export const WindowingProvider = ({ children }: TWindowingProviderProps) => {
  // State
  const [idToNodeMapping, setIdToNodeMapping] = useState<{
    [key: string]: ReactNode;
  }>({}); // [windowId: string]: ReactNode
  const [idToState, setIdToState] = useState<{ [key: string]: WindowState }>(
    {}
  ); // [windowId: string]: WindowState
  const [searchWindowPreviews, setSearchWindowPreviews] = useState<
    TIdNameTypeObjectType[]
  >([]);
  const [graphViewSelectedTab, setGraphViewSelectedTab] =
    useState<LinksWindowTabsEnum | undefined>(undefined);

  const { closeReferenceModal, isReferenceModalOpen } =
    useContext(LinkGraphContext);

  const allocateWindow = useCallback((windowId?: string): WindowState => {
    const newWindowState = new WindowState(windowId);
    setIdToState((prevIdToState) => {
      const newIdToState = { ...prevIdToState };
      newIdToState[newWindowState.windowId] = newWindowState;
      newIdToState[newWindowState.windowId].openOrder =
        Object.keys(newIdToState).length;
      return newIdToState;
    });
    return newWindowState;
  }, []);

  const reopenWindow = useCallback((windowId: string) => {
    setIdToState((prevIdToState) => {
      const newIdToState = { ...prevIdToState };
      newIdToState[windowId].openCount += 1;

      // change window orders
      const prevOrder = newIdToState[windowId].openOrder;
      Object.keys(newIdToState).forEach((key) => {
        if (newIdToState[key].openOrder > prevOrder) {
          newIdToState[key].openOrder -= 1;
        }
      });
      newIdToState[windowId].openOrder = Object.keys(newIdToState).length;

      return newIdToState;
    });
  }, []);

  // minize all windows (except one if specified)
  const minimizeAllWindows = useCallback((exceptId?: string) => {
    setIdToState((prevIdToState) => {
      const newIdToState = { ...prevIdToState };
      Object.keys(newIdToState).forEach((windowId) => {
        // if exceptId is defined and windowId is equal to exceptId, then don't minimize
        if (exceptId && windowId === exceptId) {
          newIdToState[windowId].openOrder = 1;
        } else {
          newIdToState[windowId].minimizeCount += 1;
          newIdToState[windowId].openOrder = 0;
        }
      });
      return newIdToState;
    });
  }, []);

  const addWindow = useCallback(
    (node: ReactNode, windowId?: string) => {
      const newWindow = allocateWindow(windowId);
      setIdToNodeMapping((prevIdToNodeMapping) => {
        const newIdToNodeMapping = { ...prevIdToNodeMapping };
        newIdToNodeMapping[newWindow.windowId] = node;
        return newIdToNodeMapping;
      });
      return newWindow.windowId;
    },
    [allocateWindow]
  );

  const addSearchWindow = useCallback(
    (query: IQueryDTO, node: ReactNode, actionOrigin: string) => {
      LogHelperSingleton.logWithProperties("OpenQuery", {
        QueryGuid: query.guid,
        ActionOrigin: actionOrigin,
      });

      // If the search window is already open, just reopen it
      if (idToState[query.guid]) {
        reopenWindow(query.guid);
        return;
      }

      // Create a new search window
      addWindow(node, query.guid);

      // Add the search window to the search window previews
      setSearchWindowPreviews((prevSearchWindowPreviews) => {
        const newSearchWindowPreviews = [...prevSearchWindowPreviews];
        newSearchWindowPreviews.push({
          id: query.guid,
          name: query.name,
          objectType: ObjectTypeEnum.Query,
          type: "Query",
        });
        return newSearchWindowPreviews;
      });
    },
    [addWindow, idToState, reopenWindow]
  );

  const deleteWindow = useCallback((windowId: string) => {
    setIdToNodeMapping((prevIdToNodeMapping) => {
      const newIdToNodeMapping = { ...prevIdToNodeMapping };
      delete newIdToNodeMapping[windowId];
      return newIdToNodeMapping;
    });

    setIdToState((prevIdToState) => {
      const newIdToState = { ...prevIdToState };
      delete newIdToState[windowId];
      return newIdToState;
    });

    setSearchWindowPreviews((prevSearchWindowPreviews) => {
      const newSearchWindowPreviews = prevSearchWindowPreviews.filter(
        (searchWindowPreview) => {
          return searchWindowPreview.id !== windowId;
        }
      );
      return newSearchWindowPreviews;
    });
    if (windowId === WindowingConstants.GRAPH_WINDOW_ID) {
      setGraphViewSelectedTab(undefined);
    }
  }, []);

  const openGraph = useCallback(
    (defaultSelectedTab?: LinksWindowTabsEnum) => {
      // Minimize all currently open windows
      minimizeAllWindows(WindowingConstants.GRAPH_WINDOW_ID);

      // If the graph window is already loaded, just reopen it
      if (idToState[WindowingConstants.GRAPH_WINDOW_ID]) {
        if (defaultSelectedTab) {
          setGraphViewSelectedTab(defaultSelectedTab);
        }
        reopenWindow(WindowingConstants.GRAPH_WINDOW_ID);
        if (isReferenceModalOpen) {
          closeReferenceModal();
        }
        return;
      }

      // Create a new graph window
      addWindow(
        <LinksWindow defaultSelectedTab={defaultSelectedTab} />,
        WindowingConstants.GRAPH_WINDOW_ID
      );
    },
    [
      addWindow,
      idToState,
      minimizeAllWindows,
      reopenWindow,
      closeReferenceModal,
      isReferenceModalOpen,
    ]
  );

  const updateQueryWindowState = useCallback(
    (windowId: string, queryWindowState?: IQueryWindowState) => {
      setIdToState((prevIdToState) => {
        const newIdToState = { ...prevIdToState };
        newIdToState[windowId].queryWindowState = queryWindowState;
        return newIdToState;
      });
    },
    []
  );

  // Memos
  const providerValue: TWindowingContext = useMemo(() => {
    return {
      idToState,
      searchWindowPreviews,
      addWindow,
      deleteWindow,
      reopenWindow,
      addSearchWindow,
      openGraph,
      minimizeAllWindows,
      graphViewSelectedTab,
      setGraphViewSelectedTab,
      updateQueryWindowState,
    };
  }, [
    idToState,
    searchWindowPreviews,
    addWindow,
    deleteWindow,
    reopenWindow,
    addSearchWindow,
    openGraph,
    minimizeAllWindows,
    graphViewSelectedTab,
    updateQueryWindowState,
  ]);

  const renderedWindows = useMemo(() => {
    return Object.keys(idToNodeMapping).map((windowId) => {
      const currentState = idToState[windowId];
      const isHidden = !!Object.values(idToState).find(
        (vals) => vals.openOrder > idToState[windowId].openOrder
      );
      return (
        <FindestWindow
          key={`${windowId}`}
          windowId={windowId}
          openCount={currentState.openCount}
          minimizeCount={currentState.minimizeCount}
          isHidden={isHidden}
          isGraphWindow={currentState.windowId === "graph-window"}
        >
          {idToNodeMapping[windowId]}
        </FindestWindow>
      );
    });
  }, [idToNodeMapping, idToState]);

  return (
    <WindowingContext.Provider value={providerValue}>
      {renderedWindows}
      {children}
    </WindowingContext.Provider>
  );
};
