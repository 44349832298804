// node_modules
import { useCallback, useEffect, useState } from "react";
// Types
import { TDataObject, TIdNameTypeObjectType, TLinkGraphNodeDTO } from "Types";
// Helpers
import { ReactFlowHelperSingleton } from "Helpers";
// Constants
import { LinkingControllerSingleton } from "Controllers";
// Custom hooks
import {
  useAnyLinkRemovedListener,
  useObjectDeletedListener,
  useObjectLinkedListener,
  useObjectNameChangeListener,
} from "./PubSub";
// Enums
import { ObjectTypeEnum } from "Enums";

export const useForceDirectedLinkGraph = (): TDataObject | undefined => {
  // State
  const [forceDirectedLinkGraph, setForceDirectedLinkGraph] =
    useState<undefined | TDataObject>(undefined);

  // load force directed link graph
  const loadForceDirectedLinkGraph = useCallback(async () => {
    // get link graph node
    const linkGraphNode: TLinkGraphNodeDTO[] =
      await LinkingControllerSingleton.getWholeGraphAsync();

    // get connected nodes
    const connectedNodes: TLinkGraphNodeDTO[] = linkGraphNode.reduce(
      (prev: TLinkGraphNodeDTO[], curr: TLinkGraphNodeDTO) => {
        if (
          curr.lowerLevelNodes.length > 0 ||
          linkGraphNode.find(
            (node) =>
              node.lowerLevelNodes.find(
                (lowerNode) => lowerNode.id === curr.id
              ) !== undefined
          )
        ) {
          return [...prev, curr];
        }
        return prev;
      },
      []
    );

    // get nodes and edges
    const newNodes =
      ReactFlowHelperSingleton.BuildNodesForUniverse(linkGraphNode);
    const newEdges =
      ReactFlowHelperSingleton.BuildEdgesForUniverse(connectedNodes);

    // set state
    setForceDirectedLinkGraph({ nodes: newNodes, links: newEdges });
  }, []);

  const onObjectDeleted = useCallback(
    (_: string, type: ObjectTypeEnum) => {
      if (![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(type)) return;

      loadForceDirectedLinkGraph();
    },
    [loadForceDirectedLinkGraph]
  );

  const onObjectLinked = useCallback(
    (fromObject: TIdNameTypeObjectType, toObject: TIdNameTypeObjectType) => {
      if (
        ![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
          fromObject.objectType
        ) ||
        ![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(
          toObject.objectType
        )
      ) {
        return;
      }

      loadForceDirectedLinkGraph();
    },
    [loadForceDirectedLinkGraph]
  );

  useObjectDeletedListener(onObjectDeleted);
  useAnyLinkRemovedListener(loadForceDirectedLinkGraph);
  useObjectNameChangeListener(loadForceDirectedLinkGraph);
  useObjectLinkedListener(onObjectLinked);

  // Effect
  useEffect(() => {
    loadForceDirectedLinkGraph();
  }, [loadForceDirectedLinkGraph]);

  // return force directed link graph
  return forceDirectedLinkGraph;
};
