import { FC, RefObject, useRef, useState, useEffect } from "react";
import styles from "./searchMetadataBubble.module.scss";

export type TSearchMetadataBubbleProps = {
    text: string | number,
    title?: string,
    referenceContainer?: RefObject<HTMLDivElement>,
    isSmall?: boolean
};

export const SearchMetadataBubble: FC<TSearchMetadataBubbleProps> = ({ text, title, referenceContainer, isSmall }) => {
    // State
    const [isMetadataBubbleOverflowing, setIsMetadataBubbleOverflowing] = useState<boolean>(false);
    // Ref
    const metadataBubbleRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (referenceContainer) {
            const parentContainerWidth = referenceContainer.current?.getBoundingClientRect().width;
            const metadataBubbleWidth = metadataBubbleRef.current?.getBoundingClientRect().width;
            if (metadataBubbleWidth && parentContainerWidth && metadataBubbleWidth + 8 === parentContainerWidth) {
                setIsMetadataBubbleOverflowing(true);
            }
        }
    }, [referenceContainer]);

    return (
        <div className={`${styles.searchMetadataBubble} ${isSmall ? styles.isSmall : ""}`} title={isMetadataBubbleOverflowing ? `${title}${": "}${text.toString()}` : title} ref={metadataBubbleRef}>
            <h4 className={isMetadataBubbleOverflowing ? styles.overflowing : undefined}>{text}</h4>
        </div>
    );
};