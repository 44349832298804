// React
import { FC, useEffect, useState } from "react";
// Components
import { CreatePageComment } from "../CreatePageComment/CreatePageComment";
import { PageComment } from "../PageComment/PageComment";
import { RolesChecker } from "Components";
// Enums
import { ObjectTypeEnum, RolesEnum } from "Enums";
// Types
import { TCommentv2DTO } from "Types";
// Styles
import styles from "../pageComments.module.scss";

export type TPageCommentsThreadProps = {
    objectId: string,
    objectType: ObjectTypeEnum,
    comments?: TCommentv2DTO[],
    currentUsername: string,
    onUpdateCommentCount?: (commentCount: number) => void
};

export const PageCommentsThread: FC<TPageCommentsThreadProps> = ({ objectId, objectType, comments, currentUsername, onUpdateCommentCount }) => {
    const [pageComments, setPageComments] = useState<TCommentv2DTO[] | undefined>(comments);

    useEffect(() => {
        setPageComments(comments);
    }, [comments]);

    const handleCreate = (createdComment: TCommentv2DTO) => {
        const isCommentAReply = pageComments?.find(comment => comment.id === createdComment.parentId) !== undefined;
        let newComments: TCommentv2DTO[] | undefined;

        if (!isCommentAReply) {
            newComments = [...(pageComments ?? []), createdComment];
        } else {
            newComments = pageComments?.map((comment) => {
                if (comment.id === createdComment.parentId) {
                    comment.replies = [...(comment.replies ?? []), createdComment];
                }
                return comment;
            });
        }

        setPageComments(newComments);
        const totalComments = newComments?.reduce((acc, comment) => acc + 1 + (comment.replies?.length ?? 0), 0) ?? 0;
        onUpdateCommentCount?.(totalComments);
    };

    const handleDelete = (selectedComment: TCommentv2DTO) => {
        const parentCommentId = pageComments?.find(comment => comment.replies?.find(reply => reply.id === selectedComment.id))?.id;
        
        let newComments: TCommentv2DTO[] | undefined;

        if (!parentCommentId) {
            newComments = pageComments?.filter((comment) => comment.id !== selectedComment.id);
            
        } else {
            newComments = pageComments?.map((comment) => {
                if (comment.id === parentCommentId) {
                    comment.replies = comment.replies?.filter((reply) => reply.id !== selectedComment.id);
                }
                return comment;
            });
        }

        setPageComments(newComments);
        const totalComments = newComments?.reduce((acc, comment) => acc + 1 + (comment.replies?.length ?? 0), 0) ?? 0;
        onUpdateCommentCount?.(totalComments);
    };

    return (
        <>
            {pageComments && pageComments.length > 0 && pageComments.map((comment) => (
                <div key={comment.id} className={styles.pageCommentsThread}>
                    <PageComment comment={comment} handleDelete={handleDelete}/>
                    {comment.replies && comment.replies.length > 0 && comment.replies.map(reply =>
                        <PageComment key={`${comment.id}_${reply.id}`} comment={reply} handleDelete={handleDelete} />
                    )}
                    <RolesChecker roles={[RolesEnum.External]} isExcluding={true}>
                        <CreatePageComment username={currentUsername} parentId={comment.id} handleCreate={handleCreate} objectType={objectType} objectId={objectId} emptyThread={false} />
                    </RolesChecker>
                </div>
            ))}
            <RolesChecker roles={[RolesEnum.External]} isExcluding={true}>
                <div className={styles.pageCommentsThread}>
                    <CreatePageComment username={currentUsername} handleCreate={handleCreate} objectType={objectType} objectId={objectId} emptyThread={true} />
                </div>
            </RolesChecker>
        </>
    );
};