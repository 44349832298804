// node_modules
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
// Hooks
import { useObjectDeletedListener } from "Hooks";
// Types
import { TExplorerObjectItem } from "Types";
// Helpers
import { LinkGraphHelperSingleton, ObjectTypeHelperSingleton } from "Helpers";
// Enums
import { ObjectTypeEnum } from "Enums";

export const useExplorerObjectDeletedListener = (
  listItems: TExplorerObjectItem[] | undefined,
  setListItems: React.Dispatch<
    React.SetStateAction<TExplorerObjectItem[] | undefined>
  >,
  parents: TExplorerObjectItem[] | undefined,
  setParents: React.Dispatch<
    React.SetStateAction<TExplorerObjectItem[] | undefined>
  >,
  selectedParentId: string | undefined,
  setSelectedParentId: React.Dispatch<React.SetStateAction<string | undefined>>,
  objectIdEdited: string | undefined,
  objectTypeEdited: ObjectTypeEnum | undefined
) => {
  const navigate = useNavigate();

  const onObjectDeleted = useCallback(
    (id: string, type: ObjectTypeEnum) => {
      if (
        !listItems ||
        listItems.length < 1 ||
        !objectIdEdited ||
        !objectTypeEdited ||
        ![ObjectTypeEnum.Entity, ObjectTypeEnum.Study].includes(type)
      ) {
        return;
      }

      const isCollapsedByListItemId = new Map<string, boolean | undefined>();
      LinkGraphHelperSingleton.mapIsCollapsed(
        listItems,
        isCollapsedByListItemId
      );

      let newListItems: TExplorerObjectItem[] = cloneDeep(
        LinkGraphHelperSingleton.removeItem(listItems, id)
      );

      let newParents: TExplorerObjectItem[] = cloneDeep(
        LinkGraphHelperSingleton.removeItem(parents ?? [], id)
      ).filter(
        (object) => object.lowerLevelNodes && object.lowerLevelNodes.length > 0
      );

      const uniqueChildrenParentIds =
        LinkGraphHelperSingleton.extractUniqueChildrenParentIds(newParents);
      newParents = newParents.filter((p) => !uniqueChildrenParentIds.has(p.id));

      let doResetSelectedParentId = false;

      if (parents && parents.length === 2 && newParents.length === 1) {
        newListItems = [
          {
            ...newParents[0],
            isCollapsed: false,
            lowerLevelNodes: [...newParents[0].lowerLevelNodes],
          },
        ];
        doResetSelectedParentId = !!selectedParentId;
      }

      if (newListItems.length > 0) {
        const childrenIds =
          LinkGraphHelperSingleton.getChildrenIds(newListItems);

        // object edited is a child of object deleted
        if (objectIdEdited !== id && !childrenIds.has(objectIdEdited)) {
          // refresh
          navigate(0);
          return;
        }

        // object edited is the one deleted
        if (objectIdEdited === id && !childrenIds.has(objectIdEdited)) {
          // navigate to first object in the new list items
          ObjectTypeHelperSingleton.navigateBasedOnObjectType(
            newListItems[0].objectType,
            newListItems[0].id,
            navigate
          );
          return;
        }
      }

      if (newListItems.length === 0) {
        // object edited is the one deleted and was the only one in the list
        if (objectIdEdited === id) {
          // navigate to root
          navigate("/");
        } else {
          // otherwise, object edtied is a child of object deleted
          // refresh
          navigate(0);
        }
      }

      if (doResetSelectedParentId) {
        setSelectedParentId(undefined);
      }

      LinkGraphHelperSingleton.setIsCollapsed(
        newListItems,
        isCollapsedByListItemId
      );
      setListItems(newListItems);
      setParents(newParents);
    },
    [
      listItems,
      navigate,
      objectIdEdited,
      parents,
      selectedParentId,
      setListItems,
      setParents,
      setSelectedParentId,
      objectTypeEdited,
    ]
  );

  useObjectDeletedListener(onObjectDeleted);
};
