import {
  ChangeEvent,
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useCallback,
} from "react";
import TextareaAutosize from "react-textarea-autosize";
import styles from "./textArea.module.scss";

export type TTextAreaProps = {
  setTextAreaRef?: Dispatch<SetStateAction<HTMLTextAreaElement | null>>;
  placeholder: string;
  value: string;
  onChange: (changeEvent: ChangeEvent<HTMLTextAreaElement>) => void;
  onEnter?: (text: string) => void;
  extraClassName?: string;
  isReadonly?: boolean;
};

export const TextArea: FC<TTextAreaProps> = ({
  setTextAreaRef,
  placeholder,
  value,
  onChange,
  extraClassName = "",
  onEnter,
  isReadonly,
}: TTextAreaProps) => {
  const handleOnKeyDown = (
    keyboardEvent: KeyboardEvent<HTMLTextAreaElement>
  ): void => {
    if (onEnter && keyboardEvent.key === "Enter") {
      // prevent default behaviour of adding new line
      keyboardEvent.preventDefault();
      // call onEnter callback
      onEnter(keyboardEvent.currentTarget.value);
    }
  };

  const proxyRef = useCallback(
    (tag: HTMLTextAreaElement) => {
      if (setTextAreaRef) {
        setTextAreaRef(tag);
      }
    },
    [setTextAreaRef]
  );

  return (
    <TextareaAutosize
      ref={proxyRef}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={handleOnKeyDown}
      disabled={isReadonly}
      className={[styles.textarea, extraClassName].join(" ")}
    />
  );
};
