import { ITechnologySearchResult } from "Interfaces";

export type TSunburstChart = {
  id: string;
  name: string;
  selectable?: boolean;
  children?: TSunburstChart[];
}

export const fromITechnologySearchResultsToTSunburstChart = (results: ITechnologySearchResult[]): TSunburstChart => {
  const sunburstData: TSunburstChart[] = results.map((technology) => {
    const documents = technology.documents.map((document) => {
      return {
        id: document.documentId,
        name: document.title,
      };
    }
    );
    return {
      id: technology.name,
      name: technology.name,
      selectable: true,
      children: documents,
    };
  });
  return { id: "TechnologiesChart", name: "TechnologiesChart", children: sunburstData };
};
