// React
import { FC, useContext, useState } from "react";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import { Checkbox, CreatedByAccount, FindestButton, ObjectSearchPopupContent, Popover, PubSubConnectedObjects } from "Components";
// Helpers
import { ConnectedObjectsHelperSingleton, DateHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton, UserHelperSingleton } from "Helpers";
// Hooks
import { useObjectReferenceModal } from "Hooks";
// Styles
import styles from "./queryItem.module.scss";
// Enums
import { ObjectTypeEnum, ToastTypeEnum } from "Enums";
// Constants
import { FeatureToggleConstants, GeneralConstants } from "Constants";
// Interfaces
import { IQueryDTO } from "Interfaces";
// Contexts
import { AuthContext, PubSubContext } from "Providers";

export type TQueryItemProps = {
    query: IQueryDTO,
    isSelected?: boolean,
    onCheckboxChange?: (isChecked: boolean, title: string) => void,
    onQueryClick: (query: IQueryDTO) => void,
    extraClassNames?: {
        queryItemContainer?: string,
        queryItemConnectedObjects?: string,
    },
};

export const QueryItem: FC<TQueryItemProps> = ({ query, isSelected,
        onCheckboxChange, onQueryClick, extraClassNames = {} }) => {
    // Context 
    const { pubSubHandler } = useContext(PubSubContext);
    const { auth } = useContext(AuthContext);

    // State
    const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
    const [isPreviewPopoverShown, setIsPreviewPopoverShown] = useState<boolean>(false);
    const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
    const [queryElementReference, setQueryElementReference] = useState<HTMLDivElement | null>(null);

    // Custom Hooks
    const { referenceModal, setReferenceModalProps } = useObjectReferenceModal();

    // Logic
    const onQueryClickHandler = () => {
        onQueryClick(query);
    };

    const onObjectToConnectClickAsync = async (currentQuery: IQueryDTO | undefined,
        selectedLinkToObject: TIdNameTypeObjectType): Promise<void> => {
        // if current query is not set
        if (!currentQuery) {
            // show error message
            ToastHelperSingleton
                .showToast(
                    ToastTypeEnum.Error, 
                    `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(selectedLinkToObject.objectType).toLowerCase()} to query.`
                );
            // stop execution, return
            return;
        }

        // add object to query
        await ConnectedObjectsHelperSingleton
            .addObjectToObjectAsync(selectedLinkToObject, pubSubHandler, currentQuery.guid, ObjectTypeEnum.Query);

        // close modal
        setIsSavePopupOpen(false);
    };

    const openReferenceModal = (objectId: string, objectType: ObjectTypeEnum) => {
        setReferenceModalProps((previousReferenceModalProps) => {
            return {
                ...previousReferenceModalProps,
                isOpen: true,
                id: objectId,
                type: objectType
            };
        });
    };

    // Render
    return (
        <div className={[styles.queryItemContainer, isSelected ? styles.selected : "", extraClassNames.queryItemContainer ? extraClassNames.queryItemContainer : null].join(" ")}>
            <div
                className={styles.queryItem}
                onClick={onQueryClickHandler}
                role="button"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                        onQueryClickHandler();
                    }
                }}
            >
                {isSelected !== undefined && onCheckboxChange ?
                    <div onClick={(e) => { e.stopPropagation(); }} className={styles.checkboxContainer}>
                        <Checkbox
                            isChecked={isSelected}
                            onCheckboxChange={(isChecked) => {
                                onCheckboxChange(isChecked, query.guid);
                            }}
                            theme="black"
                        />
                    </div>
                    : null
                }
                <div className={styles.queryNameContainer}>
                    <h2 className={styles.queryName}>{query.name}</h2>
                </div>
                <div className={styles.creationInformation}>
                    <div className={styles.dateAdded}>{DateHelperSingleton.getShortenedDate(query.dateCreated)}</div>
                    {query.username && (
                        <CreatedByAccount
                            email={query.username}
                            extraClassNames={{ createdByAccountContainer: styles.createdByAccountContainer }}
                        />
                    )}
                </div>
            </div>
            <PubSubConnectedObjects
                mainObjectId={query.guid}
                mainObjectType={ObjectTypeEnum.Query}
                connectedObjects={query.connectedObjects}
                onConnectToObjectClick={() => { setIsSavePopupOpen(true); }}
                setContainerElementReference={setQueryElementReference} 
                extraClassName={extraClassNames.queryItemConnectedObjects ? extraClassNames.queryItemConnectedObjects : styles.queryItemConnectedObjects}
                disableConnectToNewObjectButton={UserHelperSingleton.isUserViewer(auth) || UserHelperSingleton.isUserExternalByAuth(auth)} />
            <Popover
                referenceEl={queryElementReference} 
                isOpen={isSavePopupOpen} 
                onClickOutside={() => { setIsSavePopupOpen(false); }}
                extraClassName={styles.objectSearchPopupContainer}
                placement="bottom-start"
                exceptionDataIdentifiter={GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER}
            >
                <ObjectSearchPopupContent
                    currentObjectId={query.guid}
                    onElementClick={(result: TIdNameTypeObjectType) => { onObjectToConnectClickAsync(query, result); }} 
                    doShowRecentActivity={true}
                    openReferenceModal={openReferenceModal}
                />
            </Popover>
            {/* TODO: Enable this section again, SCSS display:none, after the preview and/or show query results part is finished */}
            <div className={styles.queryItemActions}>
                {FeatureToggleConstants.QueryPreview &&
                    <FindestButton
                        title="Show query results"
                        onClick={() => { }}
                    />
                }
                <FindestButton
                    title="Edit"
                    onClick={onQueryClickHandler}
                    buttonType="secondary"
                />
                <div
                    ref={setReferenceElement}
                    onMouseEnter={() => { setIsPreviewPopoverShown(true); }}
                    onMouseLeave={() => { setIsPreviewPopoverShown(false); }}
                >
                    {FeatureToggleConstants.QueryPreview && 
                        <FindestButton
                            title="Preview"
                            onClick={() => { }}
                            buttonType="secondary"
                        />
                    }
                </div>
                <Popover
                    referenceEl={referenceElement}
                    extraClassName={styles.previewPopover}
                    onMouseEnter={() => { setIsPreviewPopoverShown(true); }}
                    onMouseLeave={() => { setIsPreviewPopoverShown(false); }}
                    placement="left-start"
                    isOpen={isPreviewPopoverShown}
                >
                    <div className={styles.searchTermsAndFiltersContainer}>
                        <div className={styles.searchTermsContainer}>
                            <h3>Search terms</h3>
                            <div className={styles.searchTermTypeContainer}>
                                <div className={styles.searchTermType}>Functions</div>
                                <div className={styles.searchTermContainer}>
                                    <div>Inspect weld</div>
                                    <span className={styles.searchTermCriteria}>Must match</span>
                                </div>
                            </div>
                            <div className={styles.searchTermTypeContainer}>
                                <div className={styles.searchTermType}>Keywords</div>
                                <div className={styles.searchTermContainer}>
                                    <div className={styles.searchTerm}>metal</div>
                                    <span className={styles.searchTermCriteria}>Must match</span>
                                </div>
                                <div className={styles.searchTermContainer}>
                                    <div className={styles.searchTerm}>drone</div>
                                    <span className={`${styles.searchTermCriteria} ${styles.shouldMatch}`}>Should match</span>
                                </div>
                                <div className={styles.searchTermContainer}>
                                    <div className={styles.searchTerm}>laser</div>
                                    <span className={`${styles.searchTermCriteria} ${styles.shouldMatch}`}>Should match</span>
                                </div>
                                <div className={styles.searchTermContainer}>
                                    <div className={styles.searchTerm}>non-contact</div>
                                    <span className={`${styles.searchTermCriteria} ${styles.shouldMatch}`}>Should match</span>
                                </div>
                            </div>
                        </div>
                        <div className={styles.filtersContainer}>
                            <h3>Filters</h3>
                            <div className={styles.filterType}>General</div>
                            <div className={styles.filterTypesContainer}>
                                <div className={styles.filterItem}>Science only</div>
                                <div className={styles.filterItem}>Last 2 years</div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.buttonsContainer}>
                        <FindestButton
                            title="Show query results"
                            onClick={() => { }}
                        />
                        <FindestButton
                            title="Edit"
                            onClick={() => { }}
                            buttonType="secondary"
                        />
                    </div>
                </Popover>
            </div>
            {referenceModal}
        </div>
    );
};