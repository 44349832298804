// node_modules
import { FC, useContext, useState, useRef, useMemo, useEffect } from "react";
import { faLink } from "@fortawesome/pro-solid-svg-icons";
// Constants
import { GeneralConstants } from "Constants";
// Enums
import { EntityTypeEnum, ObjectTypeEnum, SavedDocumentTypeEnum } from "Enums";
// Helpers
import {
  EntityHelperSingleton,
  EntityTypeHelperSingleton,
  ObjectTypeHelperSingleton,
} from "Helpers";
// Hooks
import { useClickOutsideRef, useLinkNewEntityToQuery } from "Hooks";
// Contexts
import { PubSubContext } from "Providers";
// Types
import { TIdNameTypeObjectType } from "Types";
// Components
import {
  Checkbox,
  ObjectSearchPopupContent,
  LinkCreatedEntityModal,
} from "Components";
// Interfaces
import {
  fromIDocumentSearchResult,
  IDocumentSearchResult,
  IEntityDTO,
  IQueryDTO,
} from "Interfaces";
// Styles
import styles from "./querySaveResults.module.scss";
// Controllers
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type TQuerySaveResults = {
  documentsSelected: IDocumentSearchResult[];
  filteredDocumentResults: IDocumentSearchResult[];
  query: IQueryDTO;
  documentCount: number;
  onEntityLinkToAll: (isChecked: boolean) => void;
  onDeselectAllDocuments: () => void;
};

export const QuerySaveResults: FC<TQuerySaveResults> = ({
  documentsSelected,
  filteredDocumentResults,
  query,
  documentCount,
  onEntityLinkToAll,
  onDeselectAllDocuments,
}) => {
  const [isSavePopupOpen, setIsSavePopupOpen] = useState<boolean>(false);
  const [isCreatedEntityModalOpen, setIsCreatedEntityModalOpen] =
    useState<boolean>(false);
  const [creatingEntity, setCreatingEntity] =
    useState<IEntityDTO | undefined>(undefined);

  const saveContainerElementRef = useRef<HTMLDivElement>(null);

  // Contexts
  const { pubSubHandler } = useContext(PubSubContext);

  const isAllSavedDocumentsSelected = useMemo(() => {
    return (
      filteredDocumentResults.length > 0 &&
      filteredDocumentResults.every((element) =>
        documentsSelected.some(
          (selected) => selected.documentId === element.documentId
        )
      )
    );
  }, [filteredDocumentResults, documentsSelected]);

  const isAnySavedDocumentSelected = useMemo(() => {
    const someMatch = filteredDocumentResults.some((element) =>
      documentsSelected.find(
        (selected) => selected.documentId === element.documentId
      )
    );
    return someMatch && !isAllSavedDocumentsSelected;
  }, [filteredDocumentResults, isAllSavedDocumentsSelected, documentsSelected]);

  // Hooks
  useClickOutsideRef(
    saveContainerElementRef,
    () => {
      setIsSavePopupOpen(false);
    },
    [],
    GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
  );

  const onSaveElementClickAsync = async (
    element: TIdNameTypeObjectType,
    skipFirst?: boolean
  ): Promise<void> => {
    // close save popup
    setIsSavePopupOpen(false);

    documentsSelected.forEach(async (document, index) => {
      if (skipFirst && index === 0) {
        return;
      }
      await EntityHelperSingleton.createEntityFromQuery(
        element,
        document,
        pubSubHandler,
        query
      );
    });

    onDeselectAllDocuments();
  };

  const onCreateNewEntity = async (text: string) => {
    setIsSavePopupOpen(false);
    setIsCreatedEntityModalOpen(true);
    setCreatingEntity({
      title: text,
      type: EntityTypeEnum.Undefined,
    } as IEntityDTO);
  };

  const defaultDocument: IDocumentSearchResult = {
    documentId: documentsSelected[0]?.documentId || "",
    documentType:
      documentsSelected[0]?.documentType ||
      SavedDocumentTypeEnum.ScienceArticle,
    title: documentsSelected[0]?.title || "",
    url: documentsSelected[0]?.url || "",
    mainContents: documentsSelected[0]?.mainContents || undefined,
    isAlreadyRead: documentsSelected[0]?.isAlreadyRead || false,
    connectedObjects: documentsSelected[0]?.connectedObjects || [],
    searchInformation: documentsSelected[0]?.searchInformation || undefined,
    authorships: documentsSelected[0]?.authorships || [],
    isOpenAccess: documentsSelected[0]?.isOpenAccess || false,
    images: documentsSelected[0]?.images || [],
    highlights: documentsSelected[0]?.highlights || [],
  };

  const { linkNewEntityToQueryAsync, createdEntityObject } =
    useLinkNewEntityToQuery(
      query,
      setIsCreatedEntityModalOpen,
      fromIDocumentSearchResult(defaultDocument),
      ObjectTypeHelperSingleton.documentTypeToObjectType(
        defaultDocument.documentType
      )
    );

  useEffect(() => {
    (async () => {
      if (createdEntityObject) {
        await onSaveElementClickAsync(
          {
            id: createdEntityObject.id,
            name: createdEntityObject.title,
            type: EntityTypeHelperSingleton.getEntityTypeDisplayName(
              createdEntityObject.type
            ),
            objectType: ObjectTypeEnum.Entity,
          },
          true
        );
      }
    })();
  }, [createdEntityObject]);

  return (
    <div className={styles.querySaveResultsContainer}>
      <div className={styles.querySaveResultsContent}>
        <div className={styles.querySaveResultsHeader}>
          <Checkbox
            theme="black"
            isPartiallySelected={isAnySavedDocumentSelected}
            isChecked={isAllSavedDocumentsSelected}
            onCheckboxChange={() => {
              onEntityLinkToAll(!isAllSavedDocumentsSelected);
            }}
          />
          {documentsSelected.length > 0 && (
            <button
              type="button"
              className={styles.querySaveResultsButton}
              onClick={() => {
                setIsSavePopupOpen(true);
              }}
            >
              <FontAwesomeIcon icon={faLink} />
              Save
            </button>
          )}
          <div className={styles.querySaveResultsText}>
            {documentsSelected.length > 0 ? (
              <p>
                {documentsSelected.length} out of {documentCount} documents
                selected
              </p>
            ) : (
              <p>Select first {filteredDocumentResults.length} documents</p>
            )}
          </div>
        </div>
        {isSavePopupOpen && (
          <div className={styles.querySaveResultsPopover}>
            <div
              className={styles.querySaveResultsPopoverOverlay}
              ref={saveContainerElementRef}
            >
              <ObjectSearchPopupContent
                onElementClick={async (result: TIdNameTypeObjectType) => {
                  await onSaveElementClickAsync(result);
                }}
                doShowRecentActivity={true}
                doShowCreateButton={true}
                onCreateClick={onCreateNewEntity}
                initialLinkedObjects={query?.connectedObjects}
                initialLinkedObjectsTitle="Query Connections"
                moreActionsDropdownPopoverDataIdentifier={
                  GeneralConstants.MORE_ACTIONS_DROPDOWN_POPOVER_DATA_IDENTIFIER
                }
              />
            </div>
          </div>
        )}
        {isCreatedEntityModalOpen && (
          <LinkCreatedEntityModal
            isOpen={isCreatedEntityModalOpen}
            setIsOpen={setIsCreatedEntityModalOpen}
            creatingEntity={creatingEntity}
            onCreateEntityClickAsync={linkNewEntityToQueryAsync}
          />
        )}
      </div>
    </div>
  );
};
