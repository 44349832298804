// Controllers
import {
  DocumentControllerSingleton,
  EntityControllerSingleton,
  LinkingControllerSingleton,
} from "Controllers";
// Interfaces
import { IDocumentDetails, IDocumentSearchResult, IEntityDTO, IPubSubHandler, IQueryDTO, ISavedDocumentDTO } from "Interfaces";
// Helpers
import { ConnectedObjectsHelperSingleton, EntityTypeHelperSingleton, ObjectTypeHelperSingleton, ToastHelperSingleton } from "Helpers";
// Enums
import { LogFeatureNameEnum, ObjectTypeEnum, ToastTypeEnum, WebRequestStatusEnum } from "Enums";
// Constants
import { LinkingConstants } from "Constants";
// Types
import { TIdNameTypeObjectType } from "Types";

export class EntityHelper {
  public async createEntityFromLinkCreatedEntityModal(
    entity: IEntityDTO,
    linkDirection: string,
    setIsLinkCreatedEntityModalOpen: (isOpen: boolean) => void,
    document?: IDocumentDetails,
    documentObjectType?: ObjectTypeEnum,
    updateDocument?: (document: IDocumentDetails) => void,
    linkedObject?: TIdNameTypeObjectType,
    logProperties: { ActionOrigin?: string; QueryGuid?: string } = {}
  ) {
    const actionOrigin = logProperties.ActionOrigin;
    // Create an entity with the selected text as title
    const createdEntity = await EntityControllerSingleton.createAsync(
      entity,
      logProperties
    );

    // Check if the entity has been created otherwise display an error message and stop
    if (!createdEntity) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        `Failed to create entity named ${entity.title}.`
      );
      return;
    }

    // If the entity should be linked to another object, link it
    if (linkedObject) {
      let objectEntityResponse: WebRequestStatusEnum;

      if (linkDirection === LinkingConstants.PARENT_LINK_TYPE.value) {
        objectEntityResponse = await LinkingControllerSingleton.createToAsync(
          linkedObject.id,
          linkedObject.objectType,
          createdEntity.id,
          ObjectTypeEnum.Entity,
          actionOrigin
        );
      } else {
        objectEntityResponse = await LinkingControllerSingleton.createToAsync(
          createdEntity.id,
          ObjectTypeEnum.Entity,
          linkedObject.id,
          linkedObject.objectType,
          actionOrigin
        );
      }

      // Check if the document has been linked otherwise display an error message and stop
      const isObjectEntityLinkSuccess =
        objectEntityResponse === WebRequestStatusEnum.Success;
      if (!isObjectEntityLinkSuccess) {
        ToastHelperSingleton.showToast(
          ToastTypeEnum.Error,
          "Failed to link entity to object."
        );

        return;
      }
    }

    if (
      document &&
      documentObjectType !== undefined &&
      documentObjectType !== null
    ) {
      await this.addDocumentToEntityAsync(
        document,
        documentObjectType,
        createdEntity,
        logProperties,
        actionOrigin,
        updateDocument
      );
    }

    setIsLinkCreatedEntityModalOpen(false);
    return createdEntity;
  }

  public async addDocumentToEntityAsync(
    document: IDocumentDetails,
    documentObjectType: ObjectTypeEnum,
    createdEntity: IEntityDTO,
    logProperties: { ActionOrigin?: string; QueryGuid?: string },
    actionOrigin?: string,
    updateDocument?: (document: IDocumentDetails) => void
  ): Promise<void> {
    let isDocumentAlreadySaved = false;
    if (document.createdByUsername && document.dateAdded) {
      isDocumentAlreadySaved = true;
    } else if (document.connectedObjects) {
      /**
       * We don’t have any way to tell if the document is saved before from the search results.
       * If we add that information, it might make the search slower.
       * On the list page, check connectedObjects of the document, if there is a connected object, don’t log "SaveDocument".
       * It can still happen that we send too many save document logs,
       * because the document can be saved from plugin or create new modal. This is an edge case.
       */
      isDocumentAlreadySaved = document.connectedObjects.length > 0;
    }

    // Create or get the document to link to the entity
    const savedDocument =
      await DocumentControllerSingleton.createWithoutWebAsync(
        document.id,
        document.documentType,
        !isDocumentAlreadySaved ? logProperties : undefined
      );

    // Check if the document has been created otherwise display an error message and stop
    if (!savedDocument) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to save document."
      );
      return;
    }

    // Link the document to the entity
    const documentEntityLinkingResponse =
      await LinkingControllerSingleton.createToAsync(
        savedDocument.id,
        documentObjectType,
        createdEntity.id,
        ObjectTypeEnum.Entity,
        actionOrigin
      );

    // Check if the document has been linked otherwise display an error message and stop
    const isDocumentEntityLinkSuccess =
      documentEntityLinkingResponse === WebRequestStatusEnum.Success;
    if (!isDocumentEntityLinkSuccess) {
      ToastHelperSingleton.showToast(
        ToastTypeEnum.Error,
        "Failed to link document to entity."
      );
      return;
    }

    // Add the entity to the connected objects of the document
    const newDocument = { ...document };
    newDocument.connectedObjects = [
      ...document.connectedObjects,
      {
        id: createdEntity.id,
        name: createdEntity.title,
        objectType: ObjectTypeEnum.Entity,
        type: EntityTypeHelperSingleton.getEntityTypeDisplayName(
          createdEntity.type,
          createdEntity.customTypeName
        ),
        customTypeName: createdEntity.customTypeName,
      },
    ];

    // Refresh the document
    if (updateDocument) updateDocument(newDocument);
  }

  public async createEntityFromQuery(element: TIdNameTypeObjectType, document: IDocumentSearchResult, pubSubHandler: IPubSubHandler, query?: IQueryDTO, ) {

    /**
   * We don’t have any way to tell if the document is saved before from the search results.
   * If we add that information, it might make the search slower.
   * On the list page, check connectedObjects of the document, if there is a connected object, don’t log "SaveDocument".
   * It can still happen that we send too many save document logs,
   * because the document can be saved from plugin or create new modal. This is an edge case.
   */
  let saveDocumentLogProperties = undefined;
  let isDocumentAlreadySaved = false;
  // Check createdByUsername and dateAdded just in case we have these properties set
  if (document.createdByUsername && document.dateAdded) {
    isDocumentAlreadySaved = true;
  } else if (document.connectedObjects) {
    isDocumentAlreadySaved = document.connectedObjects.length > 0;
  }

  if (!isDocumentAlreadySaved) {
    if (query) {
      saveDocumentLogProperties = {
        ActionOrigin: LogFeatureNameEnum.AdvancedSearch,
        QueryGuid: query.guid,
      };
    }
  }

  // save document
  const savedDocument: ISavedDocumentDTO | undefined =
    await DocumentControllerSingleton.createWithoutWebAsync(
      document.documentId,
      document.documentType,
      saveDocumentLogProperties
    );

  // if savedDocument is not set
  if (!savedDocument) {
    // show error message
    ToastHelperSingleton.showToast(
      ToastTypeEnum.Error,
      `Could not link ${ObjectTypeHelperSingleton.getObjectTypeDisplayName(
        element.objectType
      ).toLowerCase()} to document.`
    );
    // stop execution, return
    return;
  }

  // add object to current document
  await ConnectedObjectsHelperSingleton.addObjectToObjectAsync(
    element,
    pubSubHandler,
    savedDocument.id,
    ObjectTypeHelperSingleton.documentTypeToObjectType(savedDocument.savedDocumentType),
    query ? LogFeatureNameEnum.AdvancedSearch : undefined
  );
}
}

export const EntityHelperSingleton = new EntityHelper();
