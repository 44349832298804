/**
 * Returns one of two values based on a boolean condition
 * @template T - The type of the return value
 * @param {boolean | undefined} discriminant - The condition to evaluate
 * @param {T} trueOutput - The value to return if discriminant is true
 * @param {T} falseOutput - The value to return if discriminant is false
 * @returns {T} - Either trueOutput or falseOutput based on the discriminant
 */
export const binaryPicker = <T>(
  discriminant: boolean | undefined,
  trueOutput: T,
  falseOutput: T
): T => {
  if (discriminant) return trueOutput;
  return falseOutput;
};
