import React from "react";
import { SearchMetadataBubble } from "../SearchMetadataBubble";
import { TGroupedDocumentSearchResult } from "Types";
import styles from "./affiliationsGroupedDirectory.module.scss";

interface AffiliationsGroupedDirectoryProps {
    groupedDocumentSearchResult: TGroupedDocumentSearchResult[] | null,
    handleGroupedDocumentClick: (groupName: string) => void
}

export const AffiliationsGroupedDirectory: React.FC<AffiliationsGroupedDirectoryProps> = ({ groupedDocumentSearchResult, handleGroupedDocumentClick }) => {
    return (
        <div className={styles.groupedResultContainer} >
            {groupedDocumentSearchResult && (
                <ul>
                    {groupedDocumentSearchResult.map((groupedResult, index) => (
                        <li key={index}>
                            <button type="button" className={styles.groupedResultItem} onClick={() => handleGroupedDocumentClick(groupedResult.groupName)}>
                                <div className={styles.groupedResultCountContainer}>
                                    <SearchMetadataBubble text={groupedResult.documentCount} isSmall />
                                </div>
                                <span className={styles.groupedResultName} >
                                    {groupedResult.groupName}</span>
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
